import React from "react"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, Link, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface FooterLink {
  label: string
  link: string
  show?: boolean
  onClick?: () => void
}

const FooterLinksSection = ({
  customFooterLinks = [],
  privacyNoticeUrl,
  organisationPrivacyNotice,
  setShouldShowCookiePopup,
  showAccessibility,
  showContactUs,
  showCookiePolicy,
  showManageCookies,
  showOrganisationPrivacyNotice,
  showPrivacyPolicy,
  showPromotionalTermsAndConditions,
  showTermsAndConditions,
  showVivupPrivacyNotice,
}: {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
  showAccessibility?: boolean
  showContactUs?: boolean
  showCookiePolicy?: boolean
  showManageCookies?: boolean
  showOrganisationPrivacyNotice?: boolean
  showPrivacyPolicy?: boolean
  showPromotionalTermsAndConditions?: boolean
  showTermsAndConditions?: boolean
  showVivupPrivacyNotice?: boolean
  customFooterLinks?: { url: string; label: string; id: string }[]
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { t, ready } = useTranslation("layout")
  const params = useParams()

  const defaultLinks: FooterLink[] = [
    {
      label: t("footer.cookiePolicy"),
      link: "/cookie_policy",
      show: showCookiePolicy,
    },
    {
      label: t("footer.manageCookies"),
      link: "#",
      onClick: () => setShouldShowCookiePopup((prev) => !prev),
      show: showManageCookies,
    },
    {
      label: t("footer.vivupPrivacyNotice"),
      link: "https://vivupbenefits.co.uk/privacy-policy",
      show: showVivupPrivacyNotice,
    },
    {
      label: t("footer.termsAndConditions"),
      link: "/terms_conditions",
      show: showTermsAndConditions,
    },
    {
      label: t("footer.promotionalTermsAndConditions"),
      link: "/promotional_terms_conditions",
      show: showPromotionalTermsAndConditions,
    },
    {
      label: t("footer.contactUs"),
      link: "/access-benefits/helpdesk",
      show: showContactUs,
    },
    {
      label: t("footer.accessibility"),
      link: "/accessibility",
      show: showAccessibility,
    },
  ]

  if (privacyNoticeUrl) {
    defaultLinks.splice(2, 0, {
      label: organisationPrivacyNotice
        ? t("footer.organisationPrivacyNotice")
        : t("footer.privacyPolicy"),
      link: privacyNoticeUrl,
      show: organisationPrivacyNotice
        ? showOrganisationPrivacyNotice
        : showPrivacyPolicy,
    })
  }

  const filteredLinks = defaultLinks.filter((link) => link.show !== false)

  const finalLinks: FooterLink[] = [
    ...filteredLinks,
    ...(customFooterLinks?.map((customLink) => ({
      label: customLink.label,
      link: customLink.url,
    })) || []),
  ]

  if (!ready) {
    return null
  }

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      data-cy="footer-links"
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {finalLinks.map((item, index) => (
          <React.Fragment key={index}>
            {item.onClick ? (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  item.onClick?.()
                }}
                sx={{
                  fontSize: "0.875rem",
                  textAlign: "center",
                  padding: "0 0.5rem",
                  color: theme.palette.white.main,
                  textTransform: "none",
                }}
                disableRipple
              >
                {item.label}
              </Button>
            ) : (
              <Link
                underline="none"
                color="inherit"
                href={item.link}
                component={"a"}
                target="_blank"
                rel="noopener"
                sx={{
                  fontSize: "0.875rem",
                  textAlign: "center",
                  padding: "0 0.5rem",
                }}
              >
                {item.label}
                <span className="screen-reader-only">(opens in a new tab)</span>
              </Link>
            )}
            {index !== finalLinks.length - 1 && (
              <Typography
                sx={{
                  margin: {
                    sm: "1rem 1rem",
                    md: "1rem 1rem",
                    lg: "1.125rem 0.1rem",
                  },
                  borderLeft: `0.0625rem solid ${theme.palette.white.main}`,
                  background: theme.palette.white.main,
                  height: "1.5rem",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Grid>

      <Typography
        variant="inherit"
        align="center"
        component={"span"}
        sx={{
          fontSize: "0.75rem",
          paddingBottom: "13px",
          paddingTop: "13px",
        }}
      >
        {params.schemeType === "cycle_to_work" && (
          <div>
            {t("footer.c2wCompanyInfoOne")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoTwo")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoThree")}
            {isMobile ? "" : <br />}
            {t("footer.c2wCompanyInfoFour")}
            {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoFive")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoSix")}
          </div>
        )}
        {params.schemeType === "cycle_to_work" ? (
          <div style={{ paddingTop: "14px" }}>
            {t("footer.c2wCompanyInfoSeven")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoEight")}
          </div>
        ) : (
          <div style={{ paddingTop: "14px" }}>
            {t("footer.companyInfoOne")} {isMobile ? <br /> : ""}
            {t("footer.companyInfoTwo")}
          </div>
        )}
      </Typography>
    </Grid>
  )
}

export default FooterLinksSection
