import { styled, css, Button as MuiButton } from "@mui/material"
import { Button } from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import { FamilyCareProps } from "./FamilyCare"
import {
  ImageGrid,
  ContentContainer,
  MainGrid,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  MainBox,
  ButtonContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"
import { ImageContainerStyle } from "./benefitSharedStyles"
import { useTranslation } from "react-i18next"

const HowbenifitWorks: React.FC<FamilyCareProps> = ({ link }) => {
  const { t, ready } = useTranslation("familyPayLanding")

  const childrenStuding = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenStuding.png"
  )
  if (!ready) {
    return null
  }
  return (
    <MainBoxContainer>
      <ContentContainer>
        <MainGrid container>
          <ImageGrid item xs={12} md={7.5}>
            <ImageContainerStyle src={childrenStuding} alt="ChildrenStuding" />
          </ImageGrid>
          <ContentGrid item xs={12} md={4.5}>
            <TextContainer>
              <TextTitle> {t("familyPayLanding.howItWorks")}</TextTitle>
              <ParagraphText>{t("familyPayLanding.childCare")}</ParagraphText>

              <ButtonContainer>
                <ButtonStyle href={link} component={"a"}>
                  {t("familyPayLanding.exploreFamilyPay")}
                </ButtonStyle>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
        </MainGrid>
      </ContentContainer>
    </MainBoxContainer>
  )
}
export default HowbenifitWorks

export const MainBoxContainer = styled(MainBox)`
  background: linear-gradient(rgb(11, 126, 176), rgb(53, 24, 95));
  width: 100%;
  background: linear-gradient(rgb(11, 126, 176), rgb(53, 24, 95)) 0% no-repeat
    padding-box;
`

export const ButtonStyle = styled(Button)`
  ${({ theme }) => css`
    && {
      max-width: 19.625rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 19.625rem;
      }
    }
  `}
` as typeof MuiButton
