import {
  SxProps,
  TextField,
  TextFieldProps,
  styled,
  InputAdornment,
} from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { useFormikContext } from "formik"
import FormikValueType from "./formikValueType"
import InfoTooltip from "../InfoTooltip"

type GenericInputProps = {
  textFieldProps: TextFieldProps
  style?: CSSProperties // inline style props passed to TextField
  sx?: SxProps
  inlineTextFieldProps?: TextFieldProps
  tooltip?: string
}

const GenericField = <T extends FormikValueType>({
  textFieldProps,
  style,
  sx,
  inlineTextFieldProps,
  tooltip,
}: GenericInputProps) => {
  const { id } = textFieldProps
  const formik = useFormikContext<T>()

  if (id) {
    const touched = formik.touched[id as keyof T]
    const error = formik.errors[id as keyof T]
    const invalid = touched && error ? true : false

    return (
      <div>
        <StyledTextField
          {...textFieldProps}
          style={style && style}
          sx={sx && sx}
          error={invalid}
          value={formik.values[id]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={invalid && (formik.errors[id] as string)}
          {...inlineTextFieldProps}
          InputProps={{
            endAdornment: tooltip ? (
              <InputAdornment position="end">
                <InfoTooltip content={tooltip} />
              </InputAdornment>
            ) : null,
          }}
        />
      </div>
    )
  }
  return <></>
}

export default GenericField

const StyledTextField = styled(TextField)`
  margin-bottom: 1.875rem;
`
