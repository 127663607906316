import { Modal, Box, Button, Grid, Link, styled, css } from "@mui/material"
import { Close } from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { CloseWindowButton } from "../../shared/affordability-calculator/Modal"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useLocation, useParams } from "react-router-dom"
import { SavingsCalculatorResult } from "../../../graphqGenaretedTypes"
import SavingCalculatorCard from "../../cycle-to-work/quote-page/SavingCalculatorCard"
import { useTranslation } from "react-i18next"

export type BenefitCardModalProps = {
  open: boolean
  handleModalClose(): void
  handleCloseModal(recalculate?: boolean): void
  data?: SavingsCalculatorResult
  quoteType?: string
  showButtons?: boolean
}

const SecondaryPopUp = ({
  open,
  handleCloseModal,
  handleModalClose,
  data,
  quoteType,
  showButtons = true,
}: BenefitCardModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const CloseHandle = () => {
    handleCloseModal()
    handleModalClose()
  }

  const params = useParams()
  const { t, ready } = useTranslation("cycleToWork")
  const location = useLocation()
  const isLandingPage = location.pathname.endsWith("cycle_to_work/landing")

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={open}
      onClose={CloseHandle}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainer
        sx={{
          overflow: "scroll",
          maxHeight: "87vh",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container columns={15}>
            <Grid item xs={8}>
              {showButtons && (
                <Button
                  onClick={() => handleCloseModal(false)}
                  sx={{ "&:hover": { backgroundColor: "transparent" } }}
                >
                  <LeftIcon sx={{ paddingTop: "10px", paddingLeft: "24px" }}>
                    <div>
                      <ArrowBackIosIcon
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontSize: "1.1rem !important",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#302557",
                      }}
                    >
                      {t("calculator.secondaryPopUp.recalculateButton")}
                    </div>
                  </LeftIcon>
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "end",
                marginTop: isDesktop ? "6px" : "7px",
                marginLeft: isDesktop ? "30px" : "13px",
              }}
            >
              <Button
                onClick={CloseHandle}
                sx={{ alignItems: "flex-end" }}
                title="Close"
              >
                <Close />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <TextContainerBox>
          <MainText>{t("calculator.secondaryPopUp.mainHeader")}</MainText>
        </TextContainerBox>

        <SavingCalculatorCard calculatedResult={data} quoteType={quoteType} />

        <Disclaimer>{t("calculator.secondaryPopUp.footer")}</Disclaimer>
        {showButtons && (
          <>
            {isLandingPage ? (
              <ExploreButton
                component={Link}
                href={`/organisations/${params.organisationId}/employee/benefits/cycle_to_work/products`}
                style={{ marginBottom: isDesktop ? "0.5rem" : "0.3rem" }}
              >
                {t("calculator.secondaryPopUp.shopOnlineButton")}
              </ExploreButton>
            ) : (
              <ExploreButton
                style={{ marginBottom: isDesktop ? "0.5rem" : "0.3rem" }}
                onClick={CloseHandle}
              >
                {t("calculator.secondaryPopUp.shopOnlineButton")}
              </ExploreButton>
            )}
          </>
        )}
        {showButtons && (
          <Link
            href={`/organisations/${params.organisationId}/employee/benefits/cycle_to_work/enter_bike_quote`}
            component="a"
            sx={{
              fontSize: "17px",
              marginTop: !isDesktop ? "15px" : "34px",
              color: "#92237F",
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            {t("calculator.secondaryPopUp.enterYourBikeQuote")}
          </Link>
        )}
        <CloseButtonContainer>
          <CloseButton variant="invisible" onClick={CloseHandle}>
            {t("calculator.designText.closeWindow")}
          </CloseButton>
        </CloseButtonContainer>
      </MainContainer>
    </Modal>
  )
}
export default SecondaryPopUp
export const Disclaimer = styled("div")`
  ${({ theme }) => css`
    font-size: 12px;
    color: #302557;
    text-align: center;
    padding-left: 22px;
    padding-right: 22px;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0px;
    }
  `}
`
export const GridContent = styled(Grid)`
  ${({ theme }) => css`
    &&& {
      padding-left: 41px;
      font-size: 16px;
      color: ${theme.palette.primary.main};
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding-left: 12px;
      }
    }
  `}
`
export const CalculateValue = styled(Grid)`
  && {
    text-align: end;
  }
`
export const LeftIcon = styled("div")`
  cursor: pointer;
  display: flex;
`
export const RightIcon = styled("div")``
export const Input = styled("input")`
  ${({ theme }) => css`
    && {
      width: 428px;
      height: 56px;
      border: 1px solid #eae9ee;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 287px;
        height: 56px;
      }
      ::placeholder {
        font-size: 17px;

        color: #666666;
        padding-left: 16.5px;
      }
    }
  `}
`
export const ExploreButton = styled(Button)`
  ${({ theme }) => css`
    && {
      display: block;
      width: 17.375rem;
      height: 3.125rem;
      margin: 2rem auto;
      margin-top: 1.815rem;
      margin-bottom: 2.188rem;
      color: ${theme.palette.white.main};
      font-size: 1rem;
      line-height: ${theme.typography.body2.lineHeight};
      text-align: center;
      border-radius: 2.625rem;
      box-shadow: ${theme.boxShadow};
      opacity: 1;
      background: transparent linear-gradient(90deg, #92237f 0%, #35185f 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 287px;
        height: 50px;
        font-size: 0.875rem;
      }
      &:hover {
        opacity: 75%;
      }
    }
  `}
` as typeof Button

export const CloseButton = styled(CloseWindowButton)`
  && {
    display: flex;
    justify-content: center;
    text-align: "center";
    margin-bottom: 1rem;
    font-size: 0.75rem;
    color: #666666;
  }
`
export const SecondaryText = styled(Box)`
  ${({ theme }) => css`
    font-size: 1rem;
    text-align: center;
    color: #35185f;
    padding: 1rem;
    padding-left: 5rem;
    padding-right: 5rem;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      font-size: 14px;
      text-align: center;
      padding-top: 14px;
    }
  `}
`
export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      border-radius: 0.938rem;
      background-color: #eae9ee;
      width: 547px;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 350px;
      }
    }
  `}
`

export const TextContainerBox = styled(Box)`
  && {
    text-align: center;
    margin-bottom: 29px;
  }
`
export const MainText = styled(Box)`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: #35185f;
    font-family: raleway;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-bottom: 0rem;
      font-size: 16px;
    }
  `}
`
export const CloseButtonContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 1.5rem;
  }
`
