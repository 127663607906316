import { Box, css, styled } from "@mui/material"
import useMentorPartnershipData from "./useMentorPartnershipData"
import BenefitCard, {
  CardOuterContainer,
  Content,
  Heading,
} from "./BenefitCard"
import { useTranslation } from "react-i18next"
import { PayrollPayBenefit } from "../../graphqGenaretedTypes"
import Loader from "../shared/Loader"

type MentorPartnership = {
  payrollPayBenefits: PayrollPayBenefit[]
}

const MentorPartnership = ({ payrollPayBenefits }: MentorPartnership) => {
  const { ready } = useTranslation("mentorPartnership")
  const mentorPartnershipData = useMentorPartnershipData()

  if (!ready || payrollPayBenefits.length === 0) {
    return <Loader />
  }
  return (
    <MainContainer>
      {payrollPayBenefits.some(
        (benefit) =>
          benefit.status.startsWith("Open") &&
          benefit.relativeUrl &&
          mentorPartnershipData?.[benefit.relativeUrl]
      ) ? (
        payrollPayBenefits.map((benefit) => {
          if (benefit.status.startsWith("Open") && benefit.relativeUrl) {
            const benefitType = benefit.relativeUrl
            const openedBenefit = mentorPartnershipData?.[benefitType]
            if (openedBenefit) {
              return (
                <BenefitCard
                  image={openedBenefit.image}
                  heading={openedBenefit.heading}
                  content={openedBenefit.content}
                  key={openedBenefit.heading}
                  link={benefit.link ?? ""}
                />
              )
            }
          }
          return null
        })
      ) : (
        <CardOuterContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Heading>
            Your Organisation does not currently have any benefits available.
          </Heading>
          <Content>
            Use the <strong>'My Account'</strong> menu to view your other
            options.
          </Content>
        </CardOuterContainer>
      )}
    </MainContainer>
  )
}

export default MentorPartnership

const MainContainer = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.palette.grey[200]};
    padding-top: 2rem;
    padding-bottom: 3rem;
  `}
`
