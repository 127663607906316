import { Grid, Container, Box, styled, css } from "@mui/material"
import { useProductData, ProductContext } from "./ProductContext"
import GalleryCarouselGrid from "../GalleryCarouselGrid"
import ModuleTabs from "../ModuleTabs"
import TabCenter from "../TabCenter"
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs"
import Loader from "../../shared/Loader"
import BottomBanner from "../BottomBanner"
import StickyACButton from "../../shared/affordability-calculator/StickyACButton"
import SavingsBanner from "../SavingsBanner"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useState } from "react"
import { updateSalaryBracketModalState } from "../../../utils/apolloClient"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { ColorStrip } from "../StoreHome"
import TopBenefitWindowInfo from "../TopBenefitWindowInfo"
import AddCardPopup from "../add-to-order/AddCardPopup"
import useIsMobileView from "../../../utils/useIsMobileView"

const savingBannerProps = {
  marginLeft: { xs: "-0.5rem", sm: "-0.75rem" },
  marginRight: { xs: "-0.5rem", sm: "-0.75rem" },
  width: { xs: "calc(100% + 1rem)", sm: "calc(100% + 1.5rem)" },
  borderRadius: 0,
}

export const Product = function () {
  const { data } = useProductData()
  const params = useParams()
  const { t, ready } = useTranslation("productDetail")
  const { windowStatus } = useContext(StoreLayoutContext)
  const [selectedSize, setSelectedSize] = useState("")
  const isMobileView = useIsMobileView()

  const selectedSizeFnc = (size: string) => {
    if (size) {
      setSelectedSize(size)
    }
  }

  useEffect(() => {
    updateSalaryBracketModalState("product")
  }, [])

  return (
    <ProductContext.Provider value={data}>
      {data.loading || !ready ? (
        <Loader />
      ) : (
        <>
          {windowStatus.status === "windowActive" && (
            <ColorStrip>
              <TopBenefitWindowInfo windowStatus={windowStatus} />
            </ColorStrip>
          )}
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                margin: { xs: "0", md: "1.0625rem auto" },
                paddingLeft: { xs: "0", sm: "1.5rem" },
              }}
            >
              <Box
                sx={{
                  margin: {
                    md: "1.375rem 0 2.25rem 0",
                  },
                  width: { xs: "100%", md: "initial" },
                }}
              >
                <CustomBreadcrumbs breadcrumbsItems={data.breadcrumbs} />
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={9.665}
              sx={{
                marginX: "auto",
              }}
            >
              <div className="topbar-container"></div>
              <GalleryCarouselGrid
                id={data.id}
                title={data.title}
                description={data.description}
                price={data.price}
                brand={data.brand}
                variant={data.variant}
                category={data.category}
                sku={data.sku}
                storeName={data.storeName}
                image={data.image}
                productImages={data.productImages}
                recommendations={data.recommendations}
                brochureGroupId={data.brochureGroupId}
                productId={data.id}
                availableSizes={data.availableSizes}
                totalAmount={data.totalAmount}
                isNhs={data.isNhs}
                productTagImage={data.productTagImage}
                department={data.department}
                brochureGroups={data.brochureGroups}
                supplierName={data.supplierName}
                releaseDate={data.releaseDate}
                status={data.status}
                reachedMaximumQuantity={data.reachedMaximumQuantity}
                maximumQuantity={data.maximumQuantity ?? 0}
                selectedSize={selectedSize}
                selectedSizeFnc={selectedSizeFnc}
              />
              {isMobileView &&
                (data.savings ? (
                  <SavingsBanner
                    savings={data.savings}
                    label={t("productDetail.SavingsBanner.label")}
                    orderValue={data.rrp.toString()}
                    brochureGroup={data.brochureGroupId.toString()}
                    savingButtonProps={savingBannerProps}
                  />
                ) : (
                  data.supplierBanner?.mobile && (
                    <MobileStoreBanner>
                      <img
                        className="bottom-banner__image"
                        src={data.supplierBanner.mobile}
                        alt={t("productDetail.BottomBanner.alt")}
                      />
                    </MobileStoreBanner>
                  )
                ))}
              <ModuleTabs
                summary={data.summary}
                techSpecs={data.techSpecs}
                releaseDate={data.releaseDate}
                status={data.status}
                preBackorderMessage={data.preBackorderMessage}
              />
            </Grid>
            {params.schemeType === "cycle_to_work" && (
              <Disclaimer>
                <StyledParagraph>
                  {t("productDetail.Product.disclamer")}
                </StyledParagraph>
              </Disclaimer>
            )}
          </Container>
          <TabCenter
            products={data.recommendations}
            similarProducts={data.recommendationsSimilarProducts}
          />
          <StickyACButton />

          {isMobileView && (
            <AddCardPopup
              id={data.id}
              title={data.title}
              totalAmount={data.totalAmount}
              brand={data.brand}
              variant={data.variant}
              category={data.category}
              sku={data.sku}
              storeName={data.storeName}
              products={data.recommendations}
              brochureGroupId={data.brochureGroupId}
              productId={data.id}
              selectedSize={selectedSize}
              department={data.department}
              supplierName={data.supplierName}
              releaseDate={data.releaseDate}
              status={data.status}
              reachedMaximumQuantity={data.reachedMaximumQuantity}
              maximumQuantity={data.maximumQuantity}
            />
          )}

          {!isMobileView &&
            (data.savings ? (
              <SavingsBanner
                savings={data.savings}
                label={t("productDetail.SavingsBanner.label")}
                orderValue={data.rrp.toString()}
                brochureGroup={data.brochureGroupId.toString()}
              />
            ) : (
              data.supplierBanner?.mobile && (
                <BottomBanner mobileBannerUrl={data.supplierBanner.mobile} />
              )
            ))}
        </>
      )}
    </ProductContext.Provider>
  )
}

const Disclaimer = styled("div")`
  margin: 0 auto 2.5rem auto;
  font-size: 12px;
  color: #35185f;
  line-height: 14px;
  text-align: center;
`

const StyledParagraph = styled("p")(
  ({ theme }) => css`
    max-width: 317px;
    margin: auto;
    ${theme.breakpoints.up("sm")} {
      max-width: 783px;
    }
  `
)

const MobileStoreBanner = styled("div")(
  ({ theme }) => css`
    width: 100%;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
    height: 3.125rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      width: calc(100% + 1.5rem);
    }
  `
)
