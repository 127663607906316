import Howbenifitworks from "./Howbenifitworks"
import Ofsted from "./Ofsted"
import ThreeSimpleSteps from "./ThreeSimpleSteps"
import { TopBanner } from "../lifestyle-savings/homepage/TopBanner"
import FamilyCare from "./FamilyCare"
import { useGetBenefitDataQuery } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import { useEffect } from "react"
import { gtmOnPageland } from "../shared/gtm-events/CustomGtmEvents"

const FamilyPay = () => {
  const params = useParams()
  const { i18n } = useTranslation()
  useEffect(() => {
    gtmOnPageland("family_pay")
  })
  const data = useGetBenefitDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      relativeUrl: "family_pay",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  const link = data.data?.employeeOrganisation?.benefit?.ssoLink
  const faqs_url = data.data?.employeeOrganisation?.benefit?.faqsUrl
  const desktopImage = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/DesktopImage.png"
  )
  const mobileImage = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/MobileImage.png"
  )

  if (link && faqs_url) {
    return (
      <div>
        <TopBanner
          desktopImageUrl={desktopImage}
          mobileImageUrl={mobileImage}
          link={null}
        />
        <FamilyCare link={link} />
        <ThreeSimpleSteps link={link} />
        <Howbenifitworks link={link} />
        <Ofsted link={faqs_url} />
      </div>
    )
  }
  return <></>
}
export default FamilyPay
