import { Box, css, styled } from "@mui/material"
import { SecondaryContainer } from "./benefitSharedStyles"
import { useTranslation } from "react-i18next"
import { Button } from "./Ofsted"
export interface FamilyCareProps {
  link: string
}

const FamilyCare: React.FC<FamilyCareProps> = ({ link }) => {
  const { t, ready } = useTranslation("familyPayLanding")
  if (!ready) {
    return null
  }
  return (
    <MainContainer>
      <SecondaryContainer>
        <div>
          <div style={{ paddingBottom: "1.25rem" }}>
            {t("familyPayLanding.childCareCost")}
          </div>
          <div style={{ paddingBottom: "1.25rem" }}>
            {t("familyPayLanding.governmentProvidedSchemes")}
          </div>
          <div>{t("familyPayLanding.fullDetails")}</div>
          <ButtonContainer>
            <Button
              variant="gradient"
              href={link}
              data-cy="start-exploring-btn"
            >
              {t("familyPayLanding.button")}
            </Button>
          </ButtonContainer>
        </div>
      </SecondaryContainer>
    </MainContainer>
  )
}
export default FamilyCare
const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 16rem;
    background-color: ${theme.palette.white.main};
    display: flex;
    justify-content: flex-start;
    color: #35185f;
    align-items: center;
    flex-direction: column;
    padding-top: 3.125rem;

    /* Tablets (max-width: 67.75rem) */
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-height: 31.25rem;
      width: unset;
      justify-content: flex-start;
    }
    /* Mobile devices (max-width: 37.4375rem) */
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 31.25rem;
      height: 100%;
      width: unset;
      justify-content: flex-start;
    }
  `}
`

export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 1.5625rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;
      margin-bottom: 0.9375rem;
    }
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-bottom: 2.5rem;
    }
  `}
`
