import { createContext } from "react"
import {
  GetCurrentUserDataQuery,
  GetCurrentUserDataQueryHookResult,
  GetStaticLayoutDataQuery,
  GetStaticLayoutDataQueryHookResult,
  useGetCurrentUserDataQuery,
  useGetStaticLayoutDataQuery,
} from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import extractSubdomain from "../../utils/extractSubdomain"

export type StaticLayoutContextData = {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  currentUserId?: number
  loading: boolean
  backendUser: boolean
  reporter: boolean
  isOrganisationSettingUser: boolean
  isPlatformUserManagementUser: boolean
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
  stopRegistration: boolean
  showAccessibility?: boolean
  showContactUs?: boolean
  showCookiePolicy?: boolean
  showManageCookies?: boolean
  showOrganisationPrivacyNotice?: boolean
  showPrivacyPolicy?: boolean
  showPromotionalTermsAndConditions?: boolean
  showTermsAndConditions?: boolean
  showVivupPrivacyNotice?: boolean
  customFooterLinks?: {
    url: string
    label: string
    id: string
  }[]
}

const defaultValue: StaticLayoutContextData = {
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  currentUserId: undefined,
  backendUser: false,
  reporter: false,
  isOrganisationSettingUser: false,
  isPlatformUserManagementUser: false,
  employeeOrganisations: [],
  loading: true,
  stopRegistration: false,
  showAccessibility: true,
  showContactUs: true,
  showCookiePolicy: true,
  showManageCookies: true,
  showOrganisationPrivacyNotice: true,
  showPrivacyPolicy: true,
  showPromotionalTermsAndConditions: true,
  showTermsAndConditions: true,
  showVivupPrivacyNotice: true,
  customFooterLinks: [],
}

const valueFromQueryResult = function (
  result:
    | GetStaticLayoutDataQueryHookResult
    | GetCurrentUserDataQueryHookResult,
  organisationId?: string
): StaticLayoutContextData {
  const data = result.data
  const loading = result.loading

  const isLayoutData = (
    data: GetStaticLayoutDataQuery | GetCurrentUserDataQuery | undefined
  ): data is GetStaticLayoutDataQuery => {
    return "employeeOrganisation" in (data || {})
  }

  const isUserData = (
    data: GetStaticLayoutDataQuery | GetCurrentUserDataQuery | undefined
  ): data is GetCurrentUserDataQuery => {
    return !("employeeOrganisation" in (data || {}))
  }

  if (isLayoutData(data)) {
    let privacyNoticeUrl = undefined
    if (organisationId) {
      privacyNoticeUrl =
        data?.employeeOrganisation?.customPrivacyNoticeUrl ??
        `/organisations/${organisationId}/privacy_notice`
    }

    return {
      privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      currentUserId: data?.currentUser?.id,
      employeeOrganisations: (
        data?.currentUser?.employeeOrganisations || []
      ).map((org) => ({
        name: org.name,
        changePortalLink: org.changePortalLink || "",
      })),
      backendUser: data?.currentUser?.backendUser || false,
      reporter: data?.employeeOrganisation?.reporter || false,
      isOrganisationSettingUser: false,
      isPlatformUserManagementUser: false,
      loading,
      stopRegistration: false,
      showAccessibility:
        data?.employeeOrganisation?.footerLinks?.showAccessibility || true,
      showContactUs:
        data?.employeeOrganisation?.footerLinks?.showContactUs || true,
      showCookiePolicy:
        data?.employeeOrganisation?.footerLinks?.showCookiePolicy || true,
      showManageCookies:
        data?.employeeOrganisation?.footerLinks?.showManageCookies || true,
      showOrganisationPrivacyNotice:
        data?.employeeOrganisation?.footerLinks
          ?.showOrganisationPrivacyNotice || true,
      showPrivacyPolicy:
        data?.employeeOrganisation?.footerLinks?.showPrivacyPolicy || true,
      showPromotionalTermsAndConditions:
        data?.employeeOrganisation?.footerLinks
          ?.showPromotionalTermsAndConditions || true,
      showTermsAndConditions:
        data?.employeeOrganisation?.footerLinks?.showTermsAndConditions || true,
      showVivupPrivacyNotice:
        data?.employeeOrganisation?.footerLinks?.showVivupPrivacyNotice || true,
      customFooterLinks:
        data?.employeeOrganisation?.footerLinks?.customFooterLinks || [],
    }
  }

  if (isUserData(data)) {
    return {
      privacyNoticeUrl: undefined,
      organisationPrivacyNotice: false,
      currentUserId: data?.currentUser?.id,
      employeeOrganisations: (
        data?.currentUser?.employeeOrganisations || []
      ).map((org) => ({
        name: org.name,
        changePortalLink: org.changePortalLink || "",
      })),
      backendUser: data?.currentUser?.backendUser || false,
      reporter: false,
      isOrganisationSettingUser:
        data?.currentUser?.isOrganisationSettingUser || false,
      isPlatformUserManagementUser:
        data?.currentUser?.isPlatformUserManagementUser || false,
      loading,
      stopRegistration: data?.portalProvider?.stopRegistration || false,
      showAccessibility: true,
      showContactUs: true,
      showCookiePolicy: true,
      showManageCookies: true,
      showOrganisationPrivacyNotice: true,
      showPrivacyPolicy: true,
      showPromotionalTermsAndConditions: true,
      showTermsAndConditions: true,
      showVivupPrivacyNotice: true,
      customFooterLinks: [],
    }
  }

  return { ...defaultValue, loading }
}

export const useStaticLayoutData = function (): StaticLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const layoutData = useGetStaticLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
    skip: params.organisationId === undefined,
  })
  const currentUrl = location.href
  const subdomain = extractSubdomain(currentUrl)
  const userData = useGetCurrentUserDataQuery({
    variables: {
      subdomain: subdomain === "Vivup" ? undefined : subdomain,
    },
    errorPolicy: "all",
  })

  return valueFromQueryResult(
    params.organisationId ? layoutData : userData,
    params.organisationId
  )
}

export const StaticLayoutContext = createContext(defaultValue)
