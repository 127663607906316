import * as yup from "yup"
import { bikeShopValidation } from "../../../../utils/yup-validation-schema/bikeShopValidation"
import { bikeValueValidation } from "../../../../utils/yup-validation-schema/bikeValueValidation"
import { brandOfBikeValidation } from "../../../../utils/yup-validation-schema/brandOfBikeValidation"
import { descriptionOfBikeValidation } from "../../../../utils/yup-validation-schema/descriptionOfBikeValidation"
import { brochureGroupValidation } from "../../../../utils/yup-validation-schema/brochureGroupValidation"
import { filesUploadValidation } from "../../../../utils/yup-validation-schema/fileUploadValidation"
import { typeOfBikeValidation } from "../../../../utils/yup-validation-schema/typeOfBikeValidation"
import { otherAccessoriesValueValidation } from "../../../../utils/yup-validation-schema/otherAccessoriesValueValidation"
import { helmetValueValidation } from "../../../../utils/yup-validation-schema/helmetValueValidation"

export const orderDetailsValidationSchema = yup.object().shape({
  bikeShop: bikeShopValidation,
  bikes: yup.array().of(
    yup.object().shape(
      {
        brandOfBike: brandOfBikeValidation,
        descriptionOfBike: descriptionOfBikeValidation,
        typeOfBike: typeOfBikeValidation,
        bikeValue: bikeValueValidation,
      },
      [
        ["brandOfBike", "typeOfBike"],
        ["brandOfBike", "bikeValue"],
        ["bikeValue", "typeOfBike"],
      ]
    )
  ),
  helmetValue: helmetValueValidation,
  otherAccessoriesValue: otherAccessoriesValueValidation,
  brochureGroup: brochureGroupValidation,
  documents: filesUploadValidation,
})
