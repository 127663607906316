/* eslint class-methods-use-this: 0 */
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { Store } from "./store/Store"
import { StoreHome } from "./store/StoreHome"
import Department from "./store/department-landing/Department"
import Basket from "./store/basket-page/Basket"
import Checkout from "./store/checkout-page/Checkout"
import { Product } from "./store/product-page/Product"
import { StoreLayout } from "./layout/store/StoreLayout"
import { LSStoreLayout } from "./layout/lifestyle-savings/LSStoreLayout"
import { CheckoutStoreLayout } from "./layout/store/CheckoutStoreLayout"
import { GlobalLayout } from "./layout/GlobalLayout"
import NotFound from "./NotFound"
import { useLayoutEffect, useState } from "react"
import { Store as LSStore } from "./lifestyle-savings/Store"
import { LocalBenefitStore } from "./lifestyle-savings/local-benefit-store/LocalBenefitStore"
import PortalHomePage from "./portal-homepage/PortalHomepage"
import LifestyleHomepage from "./lifestyle-savings/homepage/homepage"
import QuotePage from "./cycle-to-work/quote-page/QuotePage"
import FullListOfBikeShops from "./cycle-to-work/bike-shop-page/FullListOfBikeShops"
import FindABikeShop from "./cycle-to-work/bike-shop-page/find-a-bike-shop/FindABikeShop"
import { useGetAlertDataQuery } from "../graphqGenaretedTypes"
import { Alert as AlertType } from "../graphqGenaretedTypes"
import Alert from "./shared/Alert"
import Cookie from "./cookie/Cookie"
import CookieList from "./pages/cookie-list/CookieList"
import CookiePolicy from "./pages/CookiePolicy"
import { StaticLayout } from "./layout/StaticLayout"
import AccessibleNavigationAnnouncer from "./AccessibleNavigationAnnouncer"
import FamilyPay from "./benefit-landing-pages/FamilyPay"
import Analytics from "./analytics/Analytics"
import FamilyCareLanding from "./benefit-landing-pages/FamilyCareLanding"
import PrivacyNotice from "./pages/privacy-notice/PrivacyNotice"
import SignUp from "./registration/sign-up/SignUp"
import SignIn from "./registration/sign-in/SignIn"
import RegConfirm from "./registration/reg-confirm/RegConfirm"
import RegValidation from "./registration/reg-validation/RegValidation"
import RegQuestions from "./registration/reg-validation/RegQuestions"
import RegThankYou from "./registration/sign-up/RegThankYou"
import ThankYouForSubmission from "./registration/ThankYouForSubmission"
import { useTranslation } from "react-i18next"
import isTranslatedPath from "../utils/isTranslatedPath"
import AddNewBenefitTileLandingPage from "./client-admin/AddNewBenefitTileLandingPage"
import ClientAdminLayout from "./client-admin/ClientAdminLayout"
import BenefitManagement from "./benefit-managment/BenefitManagement"
import PayRollGivingLandingPage from "./store/payroll-giving/PayRollGivingLandingPage"
import PayrollGivingQuotePage from "./payroll-quote-page/PayrollGivingQuotePage"
import ChangeYourPassword from "./registration/ChangeYourPassword"
import RenewPassword from "./registration/RenewPassword"
import { RegistrationLayout } from "./registration/RegistrationLayout"
import NewNoticeboard from "./client-admin/NewNoticeboard"
import PageTitle from "./layout/PageTitle"
import NewWellbeingItem from "./client-admin/NewWellbeingItem"
import NewFamilyCare from "./client-admin/NewFamilyCare"
import EditACustomTile from "./client-admin/EditACustomTile"
import ManageLocalDiscounts from "./client-admin/local-discounts/LocalDiscounts"
import EditNoticeboard from "./client-admin/EditNoticeboard"
import EditAFamilyCareItem from "./client-admin/EditAFamilyCareItem"
import SubmitDataRequest from "./registration/SubmitDataRequest"
import NewLocalDiscount from "./client-admin/local-discounts/NewLocalDiscount"
import EditWellbeingItem from "./client-admin/EditWellbeingItem"
import ManageBenefitGroups from "./reordering-list/ManageBenefitGroups"
import EditLocalDiscount from "./client-admin/local-discounts/EditLocalDiscount"
import { LocalBenefit } from "./pages/local-benefit/LocalBenefit"
import NewFamilyPay from "./client-admin/NewFamilyPay"
import EditAFamilyPayItem from "./client-admin/EditAFamilyPayItem"
import ScrollToAnchor from "./layout/ScrollToAnchor"
import TermsConditions from "./pages/TermsConditions"

const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { i18n } = useTranslation()
  const { refetch } = useGetAlertDataQuery({
    variables: { locale: i18n.language },
    errorPolicy: "all",
  })
  const [alerts, setAlerts] = useState<AlertType[]>([])
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0

    if (isTranslatedPath(location.pathname)) {
      i18n.changeLanguage(localStorage.getItem("prefLng") || "en-GB")
    } else {
      i18n.changeLanguage("en-GB")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useLayoutEffect(() => {
    refetch().then((queryData) => {
      const freshAlerts = queryData.data?.alerts

      if (freshAlerts) {
        setAlerts(freshAlerts)
      }
    })
  }, [location.pathname, refetch, searchParams])

  useLayoutEffect(() => {
    document.documentElement.focus()
  }, [location.pathname, searchParams])

  return (
    <>
      {alerts.map((alert) => (
        <Alert
          severity={alert.severity}
          message={alert.message || ""}
          key={location.pathname + alert.message + alert.severity}
        />
      ))}
      {children}
    </>
  )
}

export default function Main() {
  const [shouldShowCookiePopup, setShouldShowCookiePopup] = useState(false)
  return (
    <>
      <a id="skip-link" href="#main-content">
        Skip to content
      </a>
      <BrowserRouter>
        <ScrollToAnchor />
        <AccessibleNavigationAnnouncer />
        <Wrapper>
          <>
            <Cookie
              shouldShowCookiePopup={shouldShowCookiePopup}
              setShouldShowCookiePopup={setShouldShowCookiePopup}
            />
            <Routes>
              <Route path="/organisations">
                <Route path=":organisationId">
                  <Route
                    path="employer"
                    element={
                      <ClientAdminLayout
                        setShouldShowCookiePopup={setShouldShowCookiePopup}
                      />
                    }
                  >
                    <Route
                      path="benefit_management"
                      element={
                        <>
                          <PageTitle title="Benefit management" />
                          <BenefitManagement />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/health_and_wellbeing"
                      element={
                        <>
                          <PageTitle title="Manage wellbeing" />
                          <ManageBenefitGroups group="help_and_support" />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/health_and_wellbeing/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit health and wellbeing" />
                          <EditWellbeingItem />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/family_care"
                      element={
                        <>
                          <PageTitle title="Family care" />
                          <ManageBenefitGroups group="family_care" />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/family_pay"
                      element={
                        <>
                          <PageTitle title="Family pay" />
                          <ManageBenefitGroups group="family_pay" />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/add_a_new_benefit_tile"
                      element={
                        <>
                          <PageTitle title="Add a new benefit" />
                          <AddNewBenefitTileLandingPage />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/local_benefit/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit Custom Benefit" />
                          <EditACustomTile />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/add_a_new_family_care"
                      element={
                        <>
                          <PageTitle title="Add a new family care item" />
                          <NewFamilyCare />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/add_a_new_family_pay"
                      element={
                        <>
                          <PageTitle title="Add a new family pay item" />
                          <NewFamilyPay />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/family_care/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit a family care item" />
                          <EditAFamilyCareItem />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/family_pay/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit a family pay item" />
                          <EditAFamilyPayItem />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/add_a_new_wellbeing"
                      element={
                        <>
                          <PageTitle title="Add a new wellbeing item" />
                          <NewWellbeingItem />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/add_a_new_noticeboard_tile"
                      element={
                        <>
                          <PageTitle title="Add a new noticeboard" />
                          <NewNoticeboard />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/noticeboard/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit noticeboard" />
                          <EditNoticeboard />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/local_discounts"
                      element={
                        <>
                          <PageTitle title="Manage local discounts" />
                          <ManageLocalDiscounts />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/local_discounts/new"
                      element={
                        <>
                          <PageTitle title="New local discount" />
                          <NewLocalDiscount />
                        </>
                      }
                    />
                    <Route
                      path="benefit_management/local_discounts/:id/edit"
                      element={
                        <>
                          <PageTitle title="Edit local discount" />
                          <EditLocalDiscount />
                        </>
                      }
                    />
                  </Route>
                  <Route path="employee">
                    <Route
                      path="lifestyle_savings"
                      element={
                        <LSStoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path="landing" element={<LifestyleHomepage />} />
                      <Route path="landing" element={<LifestyleHomepage />} />
                      <Route path="products" element={<LSStore />} />
                      <Route
                        path="local_benefits"
                        element={<LocalBenefitStore />}
                      />
                    </Route>
                    <Route
                      path="benefits"
                      element={
                        <StoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path=":schemeType">
                        <Route path="landing" element={<StoreHome />} />
                        <Route
                          path="enter_bike_quote"
                          element={<QuotePage />}
                        />
                        <Route
                          path="find_a_bike_shop/full_list_of_bike_shops"
                          element={<FullListOfBikeShops />}
                        />
                        <Route
                          path="find_a_bike_shop"
                          element={<FindABikeShop />}
                        />

                        <Route path="departments">
                          <Route path=":id" element={<Department />} />
                        </Route>
                        <Route path="products" element={<Store />} />
                        <Route path="products">
                          <Route path=":id" element={<Product />} />
                        </Route>
                        <Route path="basket" element={<Basket />} />
                      </Route>
                    </Route>
                    <Route
                      path="benefits"
                      element={
                        <CheckoutStoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path=":schemeType">
                        <Route path="checkout" element={<Checkout />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="employee"
                    element={
                      <GlobalLayout
                        setShouldShowCookiePopup={setShouldShowCookiePopup}
                      />
                    }
                  >
                    <Route path="dashboard" element={<PortalHomePage />} />
                    <Route path="local_benefits">
                      <Route path=":id" element={<LocalBenefit />} />
                    </Route>
                    <Route path="benefits">
                      <Route path="family_pay" element={<FamilyPay />} />
                      <Route
                        path="family_care"
                        element={<FamilyCareLanding />}
                      />
                      <Route
                        path="payroll_giving"
                        element={<PayRollGivingLandingPage />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path=""
                    element={
                      <StaticLayout
                        setShouldShowCookiePopup={setShouldShowCookiePopup}
                      />
                    }
                  >
                    <Route path="privacy_notice" element={<PrivacyNotice />} />
                    <Route path="analytics" element={<Analytics />} />
                    <Route
                      path="employee/benefits/payroll_giving/enter_payroll_giving_quote"
                      element={<PayrollGivingQuotePage />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path=""
                element={
                  <StaticLayout
                    setShouldShowCookiePopup={setShouldShowCookiePopup}
                  />
                }
              >
                <Route path="cookie_list" element={<CookieList />} />
                <Route path="cookie_policy" element={<CookiePolicy />} />
                <Route path="terms_conditions" element={<TermsConditions />} />
              </Route>
              <Route
                path="users"
                element={
                  <RegistrationLayout
                    setShouldShowCookiePopup={setShouldShowCookiePopup}
                  />
                }
              >
                <Route path="sign_up" element={<SignUp />} />
                <Route path="sign_in" element={<SignIn />} />
                <Route path="invitation/accept" element={<RegConfirm />} />
                <Route path=":user_id/validation_numbers">
                  <Route path="new" element={<RegValidation />} />
                  <Route
                    path=":validation_number_id/request_review"
                    element={<RegQuestions />}
                  />
                  <Route
                    path="approval_required"
                    element={<ThankYouForSubmission />}
                  />
                </Route>
                <Route path="confirmation_required" element={<RegThankYou />} />
                <Route path="password/edit" element={<ChangeYourPassword />} />
                <Route path="password_expired" element={<RenewPassword />} />
                <Route
                  path="submit_my_data_request"
                  element={<SubmitDataRequest />}
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </>
        </Wrapper>
      </BrowserRouter>
    </>
  )
}
