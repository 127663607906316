import React, { memo } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"
import { Box, css, styled } from "@mui/material"
import algoliasearch, { SearchClient } from "algoliasearch"
import type { MultipleQueriesQuery } from "@algolia/client-search"
import { Middleware } from "../../../utils/googleAnalyticsMiddleware"
import { Configure, InstantSearch } from "react-instantsearch"
import { AlgoliaFilters } from "../../layout/store/StoreLayoutContext"
import AlgoliaSearchBox from "./AlgoliaSearchBox"
import { AuthMode } from "@algolia/client-common"

type SearchBoxWrapperType = {
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  isWidgetOpen: boolean
  algoliaFilters: AlgoliaFilters
}

// if there's a search param in the URL, fire the initial query with that search term
function getSearchParam() {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("search") || ""
}

const app_id = window.ENV["ALGOLIA_APP_ID"]
const search_api_key = window.ENV["ALGOLIA_SEARCH_KEY"]
const search_index = window.ENV["ALGOLIA_INDEX"]

const algoliaClient = algoliasearch(app_id, search_api_key, {
  // no preflight request
  authMode: AuthMode.WithinQueryParameters,
})

const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests: readonly MultipleQueriesQuery[]) {
    if (requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: true,
          query: "",
          params: "",
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

const SearchBoxWrapper = memo(
  ({ setIsWidgetOpen, isWidgetOpen, algoliaFilters }: SearchBoxWrapperType) => {
    const isMobile = useIsMobileView()

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={search_index}
        initialUiState={{ [search_index]: { query: getSearchParam() } }}
      >
        <Middleware />
        <MobileOverlay
          data-element="search"
          isMobile={isMobile}
          isWidgetOpen={isWidgetOpen}
        >
          <AlgoliaSearchBox
            isWidgetOpen={isWidgetOpen}
            setIsWidgetOpen={setIsWidgetOpen}
          />
        </MobileOverlay>
        <Configure
          hitsPerPage={5}
          facets={["*"]}
          facetFilters={algoliaFilters.facetFilters}
          filters={algoliaFilters.filters}
        />
      </InstantSearch>
    )
  }
)

export default SearchBoxWrapper

interface MobileOverlayProps {
  isMobile: boolean
  isWidgetOpen: boolean
}

const MobileOverlay = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isMobile", "isWidgetOpen"].includes(String(prop)),
})<MobileOverlayProps>(({ isMobile, isWidgetOpen }) => {
  const showOverlay = isMobile && isWidgetOpen
  return css`
    position: ${showOverlay ? "fixed" : "static"};
    width: ${showOverlay ? "auto" : "100%"};
    top: ${showOverlay ? "0" : "auto"};
    left: ${showOverlay ? "0" : "auto"};
    right: ${showOverlay ? "0" : "auto"};
    bottom: ${showOverlay ? "0" : "auto"};
    background-color: ${showOverlay ? "white" : "transparent"};
    z-index: ${showOverlay ? "1300" : "auto"};

    div.MuiInputBase-root.MuiOutlinedInput-root {
      height: ${showOverlay ? "3rem" : "auto"};
    }
  `
})
