import { Theme } from "@mui/material"

export const getPopupStyle = (theme: Theme) => ({
  addToOrderBtnContainer: {
    display: "flex",
    jusifyContent: "flex-start",

    [theme.breakpoints.down("sm")]: {
      jusifyContent: "center",
    },
  },
  addToOrderBtn: {
    width: "17.5rem",
    height: "3.125rem",
    boxShadow: theme.boxShadow,
    borderRadius: "2.625remx",
    opacity: 1,

    [theme.breakpoints.down("md")]: {
      maxWidth: "21.4375rem",
      width: "100%",
      margin: "0 auto",
    },
  },
  style: {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxWidth: "68.125rem",
    maxHeight: "90%",
    boxShadow: "1.5rem",
    backgroundColor: "transparent",
    borderRadius: "0.9375rem",
    zIndex: "9999",
    overflow: "auto",
    ["@media (max-width: 86.25rem)"]: {
      maxWidth: "80%",
      maxHeight: "80%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "21.625rem",
      maxHeight: "46.875rem",
    },

    ["@media (max-height: 41.875rem)"]: {
      height: "90%",
    },
  },
  noProductsFound: {
    ["@media (max-height: 41.875rem)"]: {
      height: "auto",
    },
  },
  Carouselbg: {
    width: "100%",
    backgroundColor: theme.palette.grey[200],
    display: "flex",

    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.625rem 0",
    },
  },
  Carouseltext: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    margin: "1.625rem 0 0.4375rem 0",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0625rem",
      margin: "0.5rem 0",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0625rem",
      margin: "0.625rem 0 0.3125rem 0",
    },
  },
  textbg: {
    width: "100%",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "2.1875rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1.25rem 0",
    },
  },
  Textbgheading: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "0.625rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0625rem",
    },
  },
  Textbgsubheading: {
    width: "30%",
    color: theme.palette.primary.main,
    fontSize: "1.0625rem",
    textAlign: "center",
    margin: "1.5625rem 0",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
      margin: "0.3125rem 0 0.9375rem 0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "0.3125rem 0 0.9375rem 0",
    },
  },
  popupButton: {
    width: "17.5rem",
    height: "3.125rem",
  },
})
