import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  css,
  styled,
} from "@mui/material"
import { UseHitsProps } from "react-instantsearch"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Categories } from "./Categories"
import { getTop5Category } from "./getTop5Category"
import { memo, useContext, useEffect } from "react"
import algoliasearchHelper from "algoliasearch-helper"
import { HitType } from "./AlgoliaResults"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"

interface CustomHitsProps extends Partial<UseHitsProps> {
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleKeyDown: (e: React.KeyboardEvent<EventTarget>) => void
  listRef: React.MutableRefObject<HTMLElement[]>
  setCombinedLength: React.Dispatch<React.SetStateAction<number>>
  hits: HitType[]
  results: algoliasearchHelper.SearchResults | undefined
  searchQuery: string
}

function CustomHitsComponent({
  setIsWidgetOpen,
  handleKeyDown,
  listRef,
  setCombinedLength,
  hits,
  results,
  searchQuery,
}: CustomHitsProps) {
  const { organisationId, schemeType } = useParams()
  const [searchParams] = useSearchParams()
  const brochureGroup = searchParams.get("bg")
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const defaultImg = useBuildAwsImageUrl(i18n.language, "default_img.png")
  const { defaultHits, defaultTopCategories } = useContext(StoreLayoutContext)

  const handleClick = (link: string) => {
    navigate(link)
    setIsWidgetOpen(false)
  }

  const shouldShowAlgoliaHits = Boolean(
    searchQuery && searchQuery.length > 1 && hits
  )

  const rawCategories = shouldShowAlgoliaHits
    ? results?._rawResults[0]?.["facets"]?.["categories.name"]
    : defaultTopCategories
  const top5Categories = rawCategories && getTop5Category(rawCategories)

  const hitsToShow = shouldShowAlgoliaHits ? hits : defaultHits

  // update the combinedLength when hits or categories are updated for keyboard access
  useEffect(() => {
    const categoriesLength = top5Categories?.length ? top5Categories?.length : 0
    setCombinedLength(categoriesLength + hits.length)
  }, [top5Categories, hits, setCombinedLength])

  return (
    <Container>
      <StyledList data-cy="suggestions">
        {hitsToShow.map((hit, i) => {
          const index = i + 1
          const img = hit["product_templates.image_cdn_url"] as
            | string
            | undefined
          const link = `/organisations/${organisationId}/employee/benefits/${schemeType}/products/${
            hit.objectID
          }?${brochureGroup ? `bg=${brochureGroup}` : ""}`

          // "product_templates.name" as fallback if online_product_name not available
          const productDescription =
            hit["_highlightResult"]?.[
              "product_templates.online_product_name"
            ]?.["value"] ??
            hit["_highlightResult"]?.["product_templates.name"]?.["value"]

          return (
            <StyledListItem key={`search-box-result=${index}`}>
              <StyledListItemButton
                data-element="search"
                disableGutters
                onClick={() => handleClick(link)}
                tabIndex={-1}
                ref={(el) => {
                  if (el) {
                    listRef.current[index] = el
                  }
                }}
                onKeyDown={handleKeyDown}
              >
                <Image
                  component="img"
                  src={img ?? defaultImg}
                  alt={`search result image ${index}`}
                />
                <StyledListItemText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productDescription ?? "",
                    }}
                  />
                </StyledListItemText>
              </StyledListItemButton>
            </StyledListItem>
          )
        })}
      </StyledList>
      <Categories
        categories={top5Categories}
        setIsWidgetOpen={setIsWidgetOpen}
        query={searchQuery}
        hitsLength={hits.length}
        handleKeyDown={handleKeyDown}
        listRef={listRef}
      />
    </Container>
  )
}

export const CustomHits = memo(CustomHitsComponent)

const StyledList = styled(List)`
  padding-top: 0.875rem;
`
const StyledListItem = styled(ListItem)`
  padding-left: 0.875rem;
  padding-right: 3.125rem;
  padding-top: 0;
  padding-bottom: 0.75rem;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: 24px;
      padding-left: 34px;
      padding-right: 32px;
      padding-bottom: 12px;
    }
  `}
`
const StyledListItemText = styled(ListItemText)`
  > .MuiTypography-root {
    padding-left: 1.5rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    ${({ theme }) => css`
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        padding-left: 24px;
        font-size: 14px;
        line-height: 16px;
        font-weight: ${theme.typography.fontWeightBold};
      }
    `}
  }

  mark {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(39, 146, 191, 0.34);
  }
`

const StyledListItemButton = styled(ListItemButton)`
  padding: 0;
`

const Image = styled(Box)`
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
  flex-shrink: 0;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 80px;
      height: 80px;
    }
  `}
` as typeof Box

const Container = styled(Box)`
  position: static;
  padding-bottom: 2rem;
`
