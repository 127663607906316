import { useState } from "react"
import { useMediaQuery, useTheme } from "@mui/material"
import AnimatedIcon from "./BurgerMenuAnimatedIcon"
import Drawer from "../drawer/Drawer"
import StepMenu, { StepMenuProps } from "../step-menu/StepMenu"
import SupportLinks from "../store/SupportLinks"
import { StoreLayoutContextData } from "../../layout/store/StoreLayoutContext"

type BurgerMenu = {
  stepMenuProps?: StepMenuProps
  supportLinks?: StoreLayoutContextData["supportLinks"]
}

export default function BurgerMenu({
  stepMenuProps,
  supportLinks,
}: BurgerMenu) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [open, setOpen] = useState(false)

  let title,
    itemHierarchy,
    levels = undefined

  if (stepMenuProps !== undefined) {
    title = stepMenuProps.title
    itemHierarchy = stepMenuProps.itemHierarchy
    levels = stepMenuProps.levels
  }

  return (
    <div className="nav-item">
      {!isDesktop && (
        <Drawer
          title="Burger Menu"
          label={
            <div className="nav-item__mobile-label">
              <AnimatedIcon open={open} />
            </div>
          }
          open={open}
          setOpen={setOpen}
          content={
            <div className="burger-menu-drawer-content">
              {typeof title === "string" &&
                Array.isArray(itemHierarchy) &&
                typeof levels === "number" && (
                  // Hide "Browse by Department" when shouldShowBasketIcon === false
                  <StepMenu
                    title={title}
                    itemHierarchy={itemHierarchy}
                    levels={levels}
                    setOpen={setOpen}
                  />
                )}

              {supportLinks && (
                <SupportLinks linkList={supportLinks} setDrawerOpen={setOpen} />
              )}
            </div>
          }
        />
      )}
    </div>
  )
}
