import { useParams } from "react-router-dom"
import Loader from "../shared/Loader"
import { useBenefitTileData } from "./getBenefitTileData"
import { useState } from "react"
import {
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { useTranslation } from "react-i18next"
import scrollToElement from "../cycle-to-work/quote-page/scrollToElement"
import {
  FormContainerBox,
  MainContainer,
  PreviewButton,
  PublishButton,
  StyledFormContent,
} from "../registration/shared/mainPageStyles"
import Header from "./Header"
import { BenefitDropDownField } from "./BenefitDropDownField"
import { EnterBenefitTileField } from "./EnterBenefitTileField"
import { BenefitPageSubHeaderField } from "./BenefitPageSubHeaderField"
import ShowOnPortalPageButton from "./ShowOnPortalPageButton"
import {
  NewBenefitTileFieldsWithValidation,
  NewBenefitTileInitialValues,
  NewBenefitTileValue,
  NewFamilyPayValidationSchema,
} from "./formikUtils"
import ConfirmationModal from "./ConfirmationModal"
import { PositionDropDown } from "./PositionDropDown"
import {
  useGetBenefitCountDataQuery,
  useManageLocalBenefitMutation,
} from "../../graphqGenaretedTypes"
import Alert from "../shared/Alert"
import PreviewPopUp from "./PreviewPopUp"
import { RichTextEditor } from "../shared/RichTextEditor"
import { UrlField } from "./UrlField"
import { useNavigate } from "react-router-dom"
import { ButtonContainer } from "./NewFamilyCare"
import { DeleteButtonContainer } from "./EditACustomTile"
import DeleteLocalBenefitButton from "./DeleteLocalBenefitButton"

const TRANSLATION = "newFamilyPay"

const EditAFamilyPayItem = () => {
  const { id, organisationId } = useParams()
  const benefitTileData = useBenefitTileData(organisationId, id)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const { t, ready } = useTranslation(TRANSLATION)
  const [openPreview, setOpenPreview] = useState(false)
  const [manageLocalBenefitMutation] = useManageLocalBenefitMutation()
  const params = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetBenefitCountDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      portalColumn: "family_pay",
    },
    fetchPolicy: "no-cache",
  })
  const totalCards = data?.employerOrganisation?.benefitCount ?? 0

  if (benefitTileData.loading) {
    return <Loader />
  }

  const initialValues = { ...NewBenefitTileInitialValues }
  initialValues.editorContent = benefitTileData.editorContent
  initialValues.ShowOnPortalPage = benefitTileData.enabled
  initialValues.BenefitSubTitle = benefitTileData.headline
  initialValues.BenefitTitle = benefitTileData.name
  initialValues.PopUpRequired = benefitTileData.popUpRequired
  initialValues.position = String(benefitTileData.position)
  initialValues.SelectAnOption = benefitTileData.kind
  initialValues.BenefitUrl = benefitTileData.url
  initialValues.position = benefitTileData.position.toString()

  const handlePublishConfirm = async (
    formik: FormikProps<NewBenefitTileValue>
  ) => {
    formik.isValidating = true

    const validationErrors = await formik.validateForm()

    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true))

      const errorFieldId = NewBenefitTileFieldsWithValidation.find((field) =>
        Object.keys(validationErrors).find((errorField) => errorField == field)
      )

      scrollToElement(`#${errorFieldId}`)
      formik.isValidating = false
      return
    }
    setIsPublishModalOpen(true)
  }
  const handlePreviewClick = () => {
    setOpenPreview(true)
  }

  const handlePublish = async (
    formik: FormikProps<NewBenefitTileValue>
  ): Promise<void> => {
    formik.handleSubmit()
    setIsPublishModalOpen(false)
  }

  const handleFormSubmit = async (
    values: NewBenefitTileValue,
    formikHelper: FormikHelpers<NewBenefitTileValue>
  ) => {
    const { data } = await manageLocalBenefitMutation({
      variables: {
        content: values.editorContent,
        enabled: values.ShowOnPortalPage,
        headline: values.BenefitSubTitle,
        id,
        name: values.BenefitTitle,
        organisationId: params.organisationId ?? "",
        popUpRequired: false,
        portalColumn: "family_pay",
        position: parseInt(values.position),
        kind: values.SelectAnOption,
        url: values.BenefitUrl,
      },
    })

    const errors = data?.manageLocalBenefit?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    } else {
      navigate(
        `/organisations/${params.organisationId}/employer/benefit_management/family_pay`
      )
    }
    formikHelper.setSubmitting(false)
  }

  const handlePublishModalClose = () => {
    setIsPublishModalOpen(false)
  }

  const handlePreviewModalClose = () => {
    setOpenPreview(false)
  }

  if (!ready) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NewFamilyPayValidationSchema}
      onSubmit={(values, formikHelper) => {
        handleFormSubmit(values, formikHelper)
      }}
    >
      {(formik) => {
        return (
          <MainContainer>
            {alertMessage && (
              <Alert
                severity={alertSeverity}
                message={alertMessage}
                key={errorCount}
              />
            )}
            <Header content={t("editTitle")} />
            <StyledFormContent>
              <FormContainerBox>
                <BenefitDropDownField translation={TRANSLATION} isEditScreen />
                <EnterBenefitTileField translation={TRANSLATION} />
                {formik.values.SelectAnOption == "link" ? (
                  <UrlField translation={TRANSLATION} />
                ) : (
                  <>
                    <BenefitPageSubHeaderField translation={TRANSLATION} />
                    <RichTextEditor
                      data={formik.values.editorContent}
                      fieldName="editorContent"
                    />
                  </>
                )}
                <ShowOnPortalPageButton formikValue={formik} />
                {!loading && (
                  <PositionDropDown
                    formikValue={formik}
                    currentPosition={parseInt(formik.values.position)}
                    totalCards={totalCards}
                    infoToolTip={t("positionTooltip")}
                    translation={TRANSLATION}
                  />
                )}
                <ButtonContainer>
                  {formik.values.SelectAnOption == "landing_page" && (
                    <PreviewButton
                      onClick={handlePreviewClick}
                      disabled={
                        !formik.values.BenefitTitle ||
                        !formik.values.editorContent
                      }
                    >
                      {t("preview")}
                    </PreviewButton>
                  )}
                  <PublishButton
                    variant="gradient"
                    onClick={() => handlePublishConfirm(formik)}
                    disabled={formik.isSubmitting || formik.isValidating}
                  >
                    {t("publish")}
                  </PublishButton>
                </ButtonContainer>
                <DeleteButtonContainer>
                  <DeleteLocalBenefitButton
                    id={id || ""}
                    translation={TRANSLATION}
                    redirectUrl={`/organisations/${params.organisationId}/employer/benefit_management/family_pay`}
                  />
                </DeleteButtonContainer>
                <ConfirmationModal
                  openModal={isPublishModalOpen}
                  handleClose={handlePublishModalClose}
                  handleAction={() => handlePublish(formik)}
                  label={t(
                    formik.values.SelectAnOption === "link"
                      ? "popUpModal.publishModalHeadingLink"
                      : "popUpModal.publishModalHeadingPage"
                  )}
                  firstLine={
                    !formik.values.ShowOnPortalPage
                      ? formik.values.SelectAnOption === "landing_page"
                        ? t("popUpModal.hiddenContentPageMessage")
                        : formik.values.SelectAnOption === "link"
                        ? t("popUpModal.hiddenContentLinkMessage")
                        : t("popUpModal.publishModalFirstLine")
                      : formik.values.SelectAnOption === "landing_page"
                      ? t("popUpModal.publishModalFirstLine")
                      : formik.values.SelectAnOption === "link"
                      ? t("popUpModal.publishModalLinkFirstLine")
                      : t("popUpModal.publishModalFirstLine")
                  }
                  secondLine={t("popUpModal.publishModalSecondLine")}
                  buttonLabel={t("popUpModal.editButtonLabel")}
                />
                <PreviewPopUp
                  handleClose={handlePreviewModalClose}
                  openModal={openPreview}
                  formikValue={formik}
                />
              </FormContainerBox>
            </StyledFormContent>
          </MainContainer>
        )
      }}
    </Formik>
  )
}

export default EditAFamilyPayItem
