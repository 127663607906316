import { Box, Button, css, Modal } from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import {
  MainContainer,
  CloseButton,
  CloseButtonContainer,
  IconContainer,
  TextContainerBox,
  MainText,
  ExploreButton,
} from "../../explore-your-benefits/BenefitCardModal"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../../utils/useIsMobileView"
export type BenefitCardModalProps = {
  openModal: boolean
  handleModalClose(): void
}
const TermAndConditionPopUp = ({
  openModal,
  handleModalClose,
}: BenefitCardModalProps) => {
  const isMobile = useIsMobileView()
  const { t } = useTranslation("registration")
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainerBox
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div>
          <TextContainerBox>
            <MainText id="dialog_label">
              <span className="screen-reader-only">opened</span>
              {t("registration.termsAndConditions")}
              <span className="screen-reader-only">popUp</span>
            </MainText>
          </TextContainerBox>
          <ParagraphStyle
            style={{
              paddingBottom: !isMobile ? "1.3rem" : "1rem",
            }}
          >
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para1")}{" "}
              <a href={"https://www.vivup.co.uk/"}>
                {t("registration.termAndConditionsPopUp.para2")}{" "}
              </a>
              {t("registration.termAndConditionsPopUp.para3")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para4")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para5")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para6")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para7")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para8")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para9")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para51")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para10")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para11")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para12")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para13")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para14")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para15")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para16")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para17")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para18")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para19")}
            </InnerPara>

            <InnerPara>
              {t("registration.termAndConditionsPopUp.para20")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para21")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para22")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para23")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para24")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para25")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para26")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para27")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para28")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para29")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para30")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para31")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para32")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para33")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para34")}
            </InnerPara>
            <InnerPara>
              {t("registration.termAndConditionsPopUp.para35")}
            </InnerPara>
            <InnerPara sx={{ marginBottom: "0.1rem" }}>
              {t("registration.termAndConditionsPopUp.Version")}
            </InnerPara>
            <InnerPara sx={{ marginTop: "0.1rem" }}>
              {t("registration.termAndConditionsPopUp.lastUpdated")}
            </InnerPara>
          </ParagraphStyle>
          <ReturnRegistration
            tabIndex={0}
            style={{ marginBottom: !isMobile ? "0.5rem" : "0.3rem" }}
            onClick={() => {
              handleModalClose()
            }}
          >
            {t("registration.termAndConditionsPopUp.returnToRegistration")}
          </ReturnRegistration>
          <CloseButtonContainer>
            <CloseButton
              variant="invisible"
              onClick={() => {
                handleModalClose()
              }}
            >
              {t("registration.termAndConditionsPopUp.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </div>
        <IconContainer>
          <Button onClick={handleModalClose} aria-label="close">
            <Close
              style={{
                paddingRight: !isMobile ? "1.121rem" : "1rem",
                fontSize: "1.515rem",
                color: "#33235A",
              }}
            />
          </Button>
        </IconContainer>
      </MainContainerBox>
    </Modal>
  )
}
export default TermAndConditionPopUp
export const MainContainerBox = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      width: 92%;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        width: 40.89rem;
      }
    }
  `}
`

export const ReturnRegistration = styled(ExploreButton)`
  &&&&&&&&& {
    background: linear-gradient(90deg, #0b7eb0, #35185f);
  }
`

const ParagraphStyle = styled(Box)`
  ${({ theme }) => css`
    padding-top: 0rem;
    color: #2f1754;
    height: 423px;
    padding: 1.3rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
       padding: 1.3rem;     
  `}
`
const InnerPara = styled("p")`
  ${({ theme }) => css`
    && {
      font-size: 1rem;
      font-size: 12px;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        font-size: 1rem;
      }
    }
  `}
`
