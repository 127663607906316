import {
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  css,
  Link,
} from "@mui/material"
import {
  ArrowImage,
  MainContainer,
  Cardlayout,
  CardBox,
  TextContainer,
  ImageConatiner,
  ImageContentContainer,
  SectionText,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/FourSimpleSteps"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import { useTranslation } from "react-i18next"
import { ImageWrap } from "./benefitSharedStyles"

const ThreeSimpleSteps = ({ link }: { link: string }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { i18n } = useTranslation()
  const { t, ready } = useTranslation("familyPayLanding")

  const checkCircleBlue = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/CheckCircleBlue.png"
  )
  const listCheck = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ListCheck.png"
  )
  const locationOnFill = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/LocationOnFill.png"
  )
  const rightArrow = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/RightArrow.png"
  )
  if (!ready) {
    return null
  }
  return (
    <MainContainerStyle>
      <SectionText sx={{ textAlign: "center" }}>
        {t("familyPayLanding.simpleSteps")}
      </SectionText>
      <Cardlayout>
        <CardBoxStyle
          sx={{
            height: isDesktop ? "" : "10.75rem !important",
            paddingRight: isDesktop ? "2.5rem" : undefined,
          }}
        >
          <div>
            <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
              <ImageConatiner>
                <CycleImage src={locationOnFill} alt="LocationOnFill" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>{t("familyPayLanding.yourCareProvider")}</Label>

            <TextContainer>
              {t("familyPayLanding.click")}{" "}
              <strong>
                <Link href={link} component={"a"}>
                  {t("familyPayLanding.here")}
                </Link>
              </strong>{" "}
              {t("familyPayLanding.wideRange")}
            </TextContainer>
          </div>
        </CardBoxStyle>
        <ImageWrap>
          <div>
            <ArrowImage src={rightArrow} alt="RightArrow" />
          </div>
        </ImageWrap>
        <CardBox
          sx={{
            paddingLeft: isDesktop ? "2.5rem" : undefined,
            paddingRight: isDesktop ? "2.5rem" : undefined,
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <BasketImage src={listCheck} alt="ListCheck" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label> {t("familyPayLanding.orderRequest")}</Label>
            <TextContainer>{t("familyPayLanding.yourDetails")}</TextContainer>
          </div>
        </CardBox>
        <ImageWrap>
          <div>
            <ArrowImage src={rightArrow} alt="RightArrow" />
          </div>
        </ImageWrap>

        <CardBox sx={{ paddingLeft: isDesktop ? "2.5rem" : undefined }}>
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <PeopleImage src={checkCircleBlue} alt="CheckCircleBlue" />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>{t("familyPayLanding.approval")}</Label>
            <TextContainer>{t("familyPayLanding.onceSubmitted")}</TextContainer>
          </div>
        </CardBox>
      </Cardlayout>
    </MainContainerStyle>
  )
}
export default ThreeSimpleSteps

const CycleImage = styled("img")`
  ${({ theme }) => css`
    width: 4.625rem;
    height: 5.0625rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 3.1625rem;
      height: 3.4206rem;
    }
  `}
`

const BasketImage = styled("img")`
  ${({ theme }) => css`
    width: 4.3125rem;
    height: 3.75rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 2.7731rem;
      height: 38.15px;
    }
  `}
`

const PeopleImage = styled("img")`
  ${({ theme }) => css`
    width: 4.3125rem;
    height: 4.4375rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 2.9375rem;
      height: 2.9375rem;
    }
  `}
`

const MainContainerStyle = styled(MainContainer)`
  ${({ theme }) => css`
    background-color: ${theme.palette.grey[200]};
    && {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        height: 48.625rem;
      }
    }
  `}
`

const CardBoxStyle = styled(CardBox)`
  ${({ theme }) => css`
    &&& {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 0.625rem;
      }
    }
  `}
`

const Label = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-align: center;
    padding-top: 3.4375rem;
    font-weight: bold;
    font-size: 1rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 1.25rem;
    }
  `}
`
