import { styled, Typography, useMediaQuery, useTheme, css } from "@mui/material"
import {
  MainContainer,
  Cardlayout,
  CardBox,
  TextContainer,
  ImageConatiner,
  ImageContentContainer,
  SectionText,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/FourSimpleSteps"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"
import { ImageWrap } from "./benefitSharedStyles"
import { useTranslation } from "react-i18next"
const FamilyCareThreeStep = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("familyCareLanding")

  const childrenSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenSupport.png"
  )
  const elderCareSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ElderCareSupport.png"
  )
  const petCareSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/PetCareSupport.png"
  )
  if (!ready) {
    return null
  }
  return (
    <MainContainerStyle>
      <SectionText sx={{ textAlign: "center" }}>
        {t("familyCareLanding.accessRange")}
      </SectionText>
      <Cardlayout>
        <CardBoxStyle
          sx={{
            height: isDesktop ? "" : "15.625rem !important",
            paddingRight: isDesktop ? "2.5rem" : undefined,
          }}
        >
          <div>
            <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
              <ImageConatiner>
                <ChildrenSupportImage
                  src={childrenSupport}
                  alt="ChildrenSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label> {t("familyCareLanding.childcareSupport")}</Label>

            <TextContainer sx={{ paddingTop: "1.25rem !important" }}>
              <TextStyle>{t("familyCareLanding.earlyYearsCare")}</TextStyle>
              <TextStyle>{t("familyCareLanding.behaviour")}</TextStyle>
              <TextStyle>{t("familyCareLanding.neurodiversity")}</TextStyle>
              <TextStyle>{t("familyCareLanding.teenSupport")}</TextStyle>
            </TextContainer>
          </div>
        </CardBoxStyle>
        <ImageWrap> </ImageWrap>
        <CardBox
          sx={{
            paddingLeft: isDesktop ? "2.5rem" : undefined,
            paddingRight: isDesktop ? "2.5rem" : undefined,
            paddingTop: isDesktop ? "" : "1.25rem",
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <ElderCareSupportImage
                  src={elderCareSupport}
                  alt="ElderCareSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>{t("familyCareLanding.eldercareSupport")}</Label>
            <TextContainer sx={{ paddingTop: "1.25rem !important" }}>
              <div>{t("familyCareLanding.Sourcing")} </div>
              <TextStyle>{t("familyCareLanding.financialFunding")}</TextStyle>
              <TextStyle>{t("familyCareLanding.legalAdvice")}</TextStyle>
            </TextContainer>
          </div>
        </CardBox>
        <ImageWrap> </ImageWrap>

        <CardBox
          sx={{
            paddingLeft: isDesktop ? "2.5rem" : undefined,
            paddingTop: isDesktop ? "" : "3.125rem",
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <PetCareSupportImage
                  src={petCareSupport}
                  alt="PetCareSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>{t("familyCareLanding.petcareSupport")}</Label>
            <TextContainer sx={{ paddingTop: "1.25rem !important" }}>
              <div>{t("familyCareLanding.videoVet")}</div>
              <TextStyle>{t("familyCareLanding.petWelfare")}</TextStyle>
              <TextStyle>
                {t("familyCareLanding.behaviourSpecialists")}
              </TextStyle>
            </TextContainer>
          </div>
        </CardBox>
      </Cardlayout>
    </MainContainerStyle>
  )
}
export default FamilyCareThreeStep

const ChildrenSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 6.25rem;
    height: 6.25rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 2.3844rem;
      height: 3.4206rem;
    }
  `}
`

const ElderCareSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 6.25rem;
    height: 6.25rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 2.7731rem;
      height: 2.3844rem;
    }
  `}
`

const PetCareSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 6.25rem;
    height: 6.25rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 2.9375rem;
      height: 2.9375rem;
    }
  `}
`

const MainContainerStyle = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        height: 56.25rem;
      }
    }
  `}
`
const CardBoxStyle = styled(CardBox)`
  ${({ theme }) => css`
    &&& {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 0.625rem;
      }
    }
  `}
`
const Label = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-align: center;
    padding-top: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 1.25rem;
    }
  `}
`
const TextStyle = styled("div")`
  ${({ theme }) => css`
    && {
      padding-top: 0.5rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 0.25rem;
      }
    }
  `}
`
