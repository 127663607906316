import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  css,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import snakeToCamel from "../../../../utils/snakeToCamel"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"
import { ImageWrapper } from "../../../benefit-landing-pages/benefitSharedStyles"

const FourSimpleSteps = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()
  const { t, i18n, ready } = useTranslation(
    snakeToCamel(params.schemeType || "bike_shop")
  )
  const checkCircle = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/CheckCircle.png"
  )
  const pedalBike = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/PedalBike.png"
  )
  const people = useBuildAwsImageUrl(i18n.language, "cycleToWork/People.png")
  const rightArrow = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/RightArrow.png"
  )
  const shoppingBasket = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/ShoppingBasket.png"
  )

  if (!ready) {
    return null
  }

  return (
    <MainContainer data-cy="simple-steps-outer-container">
      <SectionText>{t("landing.fourSimpleStep.mainHeader")}</SectionText>
      <Cardlayout data-cy="simple-steps-inner-container">
        <CardBox sx={{ height: isDesktop ? "" : "172px !important" }}>
          <div>
            <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
              <ImageConatiner>
                <CycleImage
                  src={pedalBike}
                  alt={t("landing.fourSimpleStep.pedalBike")}
                />
              </ImageConatiner>
            </ImageContentContainer>

            <TextContainer>{t("landing.fourSimpleStep.step1")}</TextContainer>
          </div>
        </CardBox>
        <ImageWrapper>
          <div>
            <ArrowImage
              src={rightArrow}
              alt={t("landing.fourSimpleStep.rightArrow")}
            />
          </div>
        </ImageWrapper>
        <CardBox>
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <BasketImage
                  src={shoppingBasket}
                  alt={t("landing.fourSimpleStep.shoppingBasket")}
                />
              </ImageConatiner>
            </ImageContentContainer>

            <TextContainer>{t("landing.fourSimpleStep.step2")}</TextContainer>
          </div>
        </CardBox>
        <ImageWrapper>
          <div>
            <ArrowImage
              src={rightArrow}
              alt={t("landing.fourSimpleStep.rightArrow")}
            />
          </div>
        </ImageWrapper>
        <CardBox>
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <CheckImage
                  src={checkCircle}
                  alt={t("landing.fourSimpleStep.checkCircle")}
                />
              </ImageConatiner>
            </ImageContentContainer>

            <TextContainer>{t("landing.fourSimpleStep.step3")}</TextContainer>
          </div>
        </CardBox>
        <ImageWrapper>
          <div>
            <ArrowImage
              src={rightArrow}
              alt={t("landing.fourSimpleStep.rightArrow")}
            />
          </div>
        </ImageWrapper>
        <CardBox>
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <PeopleImage
                  src={people}
                  alt={t("landing.fourSimpleStep.people")}
                />
              </ImageConatiner>
            </ImageContentContainer>
            <TextContainer>{t("landing.fourSimpleStep.step4")}</TextContainer>
          </div>
        </CardBox>
      </Cardlayout>
    </MainContainer>
  )
}
export default FourSimpleSteps
export const ArrowImage = styled("img")`
  width: 24px;
  height: 39px;
`
const CycleImage = styled("img")`
  ${({ theme }) => css`
    width: 79px;
    height: 53px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 50.6px;
      height: 33.73px;
    }
  `}
`
const BasketImage = styled("img")`
  ${({ theme }) => css`
    width: 69px;
    height: 60px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 44.37px;
      height: 38.15px;
    }
  `}
`
const CheckImage = styled("img")`
  ${({ theme }) => css`
    width: 63px;
    height: 63px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 40px;
      height: 40px;
    }
  `}
`
const PeopleImage = styled("img")`
  ${({ theme }) => css`
    width: 51px;
    height: 63px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 33px;
      height: 40px;
    }
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 522px;
    background-color: ${theme.palette.white.main};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 893px;
      justify-content: flex-start;
    }
  `}
`
export const CardBox = styled(Box)`
  ${({ theme }) => css`
    height: 293px;
    max-width: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 334px;
      width: 100%;
      height: 200px;
    }
  `}
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      max-width: 334px;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 250px;
    width: 100%;
    height: 139px;
    padding-top: 32px;
    text-align: center;
    color: ${theme.palette.primary.main};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-family: Raleway;
    font-weight: 500;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 334px;
      font-size: 14px;
      width: 100%;
      height: 64px;
      padding-top: 18px;
    }
  `}
`

export const ImageConatiner = styled(Box)`
  ${({ theme }) => css`
    max-width: 122px;
    width: 100%;
    height: 122px;
    background-color: white;
    opacity: 1;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 78px;
      width: 78px;
    }
  `}
`
export const ImageContentContainer = styled("div")`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 40px;
    }
  `}
`
export const SectionText = styled(Typography)`
  ${({ theme }) => css`
    max-width: 657px;
    width: 100%;
    height: 58px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    font-family: Raleway;
    color: ${theme.palette.primary.main};
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 273px;
      width: 100%;
      height: 61px;
      font-size: 1rem;
      margin-bottom: 13px;
      text-align: center;
      padding-top: 32px;
      line-height: 20px;
    }
  `}
`
