import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useCallback, useEffect, useState } from "react"
import { Button, Chip, Container, Grid, styled } from "@mui/material"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { Item } from "./BenefitManagementContext"
import Card from "./Card"
import { MainText } from "../explore-your-benefits/BenefitCardCarouselGrid"
import useIsMobileView from "../../utils/useIsMobileView"
import { AddCustomTile } from "./AddCustomTile"
import { useTranslation } from "react-i18next"
import { useUpdatePositionsMutation } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import Alert from "../shared/Alert"

const CardContainer = ({
  benefits,
  enabled,
  canEdit,
}: {
  benefits: Item[]
  enabled: boolean
  canEdit: boolean
}) => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const isMobile = useIsMobileView()
  const [cards, setCards] = useState<Item[]>(benefits)
  const [isEditing, setIsNotEditing] = useState(false)
  const [updatePositionsMutation] = useUpdatePositionsMutation()
  const params = useParams()
  const { t, ready } = useTranslation("clientAdminPage")

  useEffect(() => {
    setCards(benefits)
  }, [benefits])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards]

      const dragId = updatedCards[dragIndex].position
      const hoverId = updatedCards[hoverIndex].position

      const draggedCard = updatedCards[dragIndex]
      updatedCards[dragIndex] = updatedCards[hoverIndex]
      updatedCards[hoverIndex] = draggedCard

      updatedCards[dragIndex] = {
        ...updatedCards[dragIndex],
        position: dragId,
      }
      updatedCards[hoverIndex] = {
        ...updatedCards[hoverIndex],
        position: hoverId,
      }

      return updatedCards
    })
  }, [])

  const handleClick = async () => {
    if (isEditing) {
      const { data } = await updatePositionsMutation({
        variables: {
          organisationId: params.organisationId ?? "",
          entityName: "LocalScheme",
          items: cards
            .filter((card) => !isNaN(parseInt(card.id)))
            .map((card) => ({ id: card.id, position: card.position })),
        },
      })

      const errors = data?.updatePositions?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      }
    }

    setIsNotEditing(!isEditing)
  }
  // Function to check if the button should be disabled
  const shouldDisableEditButton = useCallback(() => {
    // Count the number of cards that are NOT locked and have valid IDs
    const validCardsCount = cards.filter(
      (card) => !card.lock && !isNaN(parseInt(card.id))
    ).length

    // Disable the button if there are less than two valid cards
    return validCardsCount < 2
  }, [cards])

  const renderCard = useCallback(
    (card: Item, index: number) => (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        lock={card.lock}
        status={card.status}
        moveCard={moveCard}
        isEditing={isEditing}
        image={card.image}
        link={card.link}
        relativeUrl={card.relativeUrl}
      />
    ),
    [moveCard, isEditing]
  )
  if (!ready) {
    return null
  }
  return (
    <DndProvider backend={HTML5Backend}>
      {alertMessage && (
        <Alert
          severity={alertSeverity}
          message={alertMessage}
          key={errorCount}
        />
      )}
      {!isMobile ? (
        <GridContainer id="manage_your_benefits">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <MainText
              style={{
                paddingBottom: "2rem",
              }}
            >
              <div className="d-flex content-center">
                <h4>{t("clientAdminPage:clientAdminReordering.header")}</h4>
                {!enabled && (
                  <StyledChip
                    label={t(
                      "clientAdminPage:clientAdminReordering.sectionDisabled"
                    )}
                  />
                )}
              </div>
            </MainText>
            {canEdit && (
              <Button
                variant="text"
                style={{ paddingRight: "1.25rem" }}
                onClick={handleClick}
                disabled={shouldDisableEditButton()}
              >
                {!isEditing ? (
                  <>
                    <ModeEditOutlineOutlinedIcon
                      sx={{ paddingRight: "0.5rem" }}
                    />
                    {t("clientAdminPage:clientAdminReordering.editLayout")}
                  </>
                ) : (
                  <>
                    <SaveOutlinedIcon sx={{ paddingRight: "0.5rem" }} />
                    {t("clientAdminPage:clientAdminReordering.saveLayout")}
                  </>
                )}
              </Button>
            )}
          </div>
          <Grid container sx={{ ...containerStyle }}>
            <Grid container rowGap={{ xs: 6, md: 6, lg: 6 }}>
              {cards.map((card, i) => (
                <Grid item key={card.id} xs={6} md={2} lg={2}>
                  {renderCard(card, i)}
                </Grid>
              ))}
              {!isEditing && canEdit && (
                <Grid key="new-card" item xs={6} md={2} lg={2}>
                  <AddCustomTile />
                </Grid>
              )}
            </Grid>
          </Grid>
        </GridContainer>
      ) : (
        <Grid
          container
          sx={{
            paddingLeft: "0rem",
            paddingRight: "0rem",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <Grid
            item
            xs={11}
            sm={7.2}
            sx={{ margin: "0 auto", paddingLeft: "0rem" }}
          >
            <div
              style={{
                display: "block",
              }}
            >
              <MainText
                style={{
                  paddingBottom: !isMobile ? "2rem" : "",
                }}
              >
                <h4 style={{ paddingLeft: "0.3rem" }}>
                  {t("clientAdminPage:clientAdminReordering.header")}
                </h4>
              </MainText>
              <MainText>
                <Button
                  variant="text"
                  style={{ paddingLeft: "0.3rem" }}
                  onClick={handleClick}
                  disabled={shouldDisableEditButton()}
                >
                  {!isEditing ? (
                    <>
                      <ModeEditOutlineOutlinedIcon
                        sx={{ paddingRight: "0.5rem" }}
                      />
                      {t("clientAdminPage:clientAdminReordering.editLayout")}
                    </>
                  ) : (
                    <>
                      <SaveOutlinedIcon sx={{ paddingRight: "0.5rem" }} />
                      {t("clientAdminPage:clientAdminReordering.saveLayout")}
                    </>
                  )}
                </Button>
              </MainText>
            </div>
            <SecondaryGrid
              container
              rowGap={{ xs: 6, md: 6, lg: 6 }}
              columns={{ xs: 4 }}
            >
              {cards.map((card, i) => (
                <Grid
                  key={card.id}
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: "0rem",
                  }}
                >
                  {renderCard(card, i)}
                </Grid>
              ))}
              {!isEditing ? (
                <Grid
                  key="new-card"
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: "0rem",
                  }}
                >
                  <AddCustomTile />
                </Grid>
              ) : (
                ""
              )}
            </SecondaryGrid>
          </Grid>
        </Grid>
      )}
    </DndProvider>
  )
}

export default CardContainer

const containerStyle = {
  gap: "0.5rem",
  width: "100%",
  paddingBottom: "1.875rem",
}

const GridContainer = styled(Container)`
  && {
    max-width: 69.25rem;
    width: 93%;
    margin: auto;
    margin-top: 1.875rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

const StyledChip = styled(Chip)`
  && {
    margin-left: 1rem;
    font-weight: bold;
    background-color: red;
    color: white;
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
`

const SecondaryGrid = styled(Grid)`
  && {
    padding-top: 1.25rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`
