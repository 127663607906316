import { styled, css, Button as MuiButton } from "@mui/material"
import { WaveSvgCtw } from "../shared/wave-svg/WaveSvgCtw"
import {
  MainBox,
  WaveBackgroundContainer,
  ContentContainer,
  ImageGrid,
  MainGrid,
  ImageContainer,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  ButtonContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import { ButtonStyle } from "./Howbenifitworks"
import { FamilyCareProps } from "./FamilyCare"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"
import { useTranslation } from "react-i18next"
const FamilyCareFaq: React.FC<FamilyCareProps> = ({ link }) => {
  const { t, ready } = useTranslation("familyCareLanding")

  const childrenPlaying = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenPlaying.png"
  )
  if (!ready) {
    return null
  }
  return (
    <MainBox>
      <WaveBackgroundContainer>
        <WaveSvgCtw />
        <ContentContainer>
          <MainGrid container>
            <ContentGrid item xs={12} md={5}>
              <TextContainer>
                <TextTitle>{t("familyCareLanding.faq")}</TextTitle>
                <ParagraphText>
                  {t("familyCareLanding.faqAnswer")}
                </ParagraphText>

                <ButtonContainer>
                  <Button
                    variant="gradient"
                    href={link}
                    component="a"
                    target="_blank"
                    aria-label="View Family Care FAQs"
                    rel="noopener"
                  >
                    {t("familyCareLanding.viewFaq")}
                  </Button>
                </ButtonContainer>
              </TextContainer>
            </ContentGrid>
            <ImageGrid item xs={12} md={7}>
              <ImageContainer
                src={childrenPlaying}
                alt="ChildrenPlayingImage"
              />
            </ImageGrid>
          </MainGrid>
        </ContentContainer>
      </WaveBackgroundContainer>
      <span className="screen-reader-only">
        View Family Care FAQs (opens in a new tab)
      </span>
    </MainBox>
  )
}
export default FamilyCareFaq

export const Button = styled(ButtonStyle)`
  ${({ theme }) => css`
    && {
      color: ${theme.palette.white.main};
    }
  `}
` as typeof MuiButton
