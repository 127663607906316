import BikeDetails from "./BikeDetails"
import BikeShop from "./BikeShop"
import UploadQuoteFiles from "./UploadQuoteFiles"
import getTextFieldsProps from "./fields/orderDetailsFields"
import GenericField from "../../shared/form/GenericField"
import { useDefaultUserData, useFormData } from "./QuotePageContext"
import GenericSelect from "../../shared/form/GenericSelect"
import { MenuItem, TextField, Typography } from "@mui/material"
import BikeQuote from "./BikeQuote"
import { useFormikContext } from "formik"
import { FormikValue } from "./initialValues"
import Loader from "../../shared/Loader"
import { Footnote } from "./Footnote"
import BikeQuoteFootnote from "./BikeQuoteFootnote"
import { useEffect, useState } from "react"
import useTriggerSavingCalculationsCall from "./useTriggerSavingCalculationsCall"
import validateDecimal from "./validateDecimal"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

type OrderDetailsType = {
  isOverQuoteLimit: boolean
  setOverQuoteLimit: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderDetails = ({
  isOverQuoteLimit,
  setOverQuoteLimit,
}: OrderDetailsType) => {
  const { t, ready } = useTranslation("bikeQuote")
  const { formData } = useFormData()
  const formik = useFormikContext<FormikValue>()
  const { schemeType } = useParams()
  const isNetBikeShop = schemeType === "bike_shop"

  const helmetValue = formik.values.helmetValue
    ? Number(formik.values.helmetValue)
    : 0
  const otherAccessoriesValue = formik.values.otherAccessoriesValue
    ? Number(formik.values.otherAccessoriesValue)
    : 0
  const totalBikesValues = formik.values.bikes.reduce((total, presentArray) => {
    return (total += presentArray.bikeValue
      ? Number(presentArray.bikeValue)
      : 0)
  }, 0)
  const totalOrderValue = (
    totalBikesValues +
    helmetValue +
    otherAccessoriesValue
  ).toFixed(2)
  const orderValueText = `${t(
    "fieldLabels.totalOrderValue"
  )}: £${totalOrderValue}`

  const { calculatedResult, quoteType } = useTriggerSavingCalculationsCall({
    costOfQuote: totalOrderValue,
    brochureGroupId: formik.values.brochureGroup,
    triggerCalculation: true,
  })

  const [brochureGroupLabel, setBrochureGroupLabel] = useState("")

  useEffect(() => {
    // if only one brochureGroups set the brochureGroupsId directly
    formData.brochureGroups.length === 1 &&
      formik.setFieldValue("brochureGroup", formData.brochureGroups[0].id)

    // if more than one brochureGroup, then set the label when mounted
    // if less than one brochureGroup the select box won't render so no need to deal with this in the useEffect
    if (formData.brochureGroups.length > 1) {
      const terms = formData?.brochureGroups.map(({ term }) => term)
      setBrochureGroupLabel(
        t("brochureGroupLabel", {
          term1: terms[0],
          term2: terms[1],
        })
      )
    }
    // eslint-disable-next-line
  }, [formData.loading])

  const {
    userData: { quoteLimit },
  } = useDefaultUserData()

  const exceedLimitStyles = {
    sx: {
      "& .MuiInputBase-input.Mui-disabled": {
        textFillColor: "#c5291c !important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#c5291c !important",
          opacity: 0.26,
        },
      },
    },
    helperText: `${t("exceedLimit")}: £${quoteLimit}`,
    error: true,
  }

  const isExceedLimit = Number(totalOrderValue) > quoteLimit

  const handleDecimalInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const isValidDecimal = validateDecimal(e)
    if (isValidDecimal) {
      formik.handleChange(e)
    }
  }

  // see if total order value is above limit
  // totalOrderValue is not controlled by formik because it involves complication in validation schema, and this field is not touched, so not necessary to be controlled by formik
  useEffect(() => {
    if (isExceedLimit) {
      setOverQuoteLimit(true)
    } else {
      setOverQuoteLimit(false)
    }
  }, [isExceedLimit]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    helmetValueProps,
    otherAccessoriesValueProps,
    totalOrderValueProps,
    brochureGroupProps,
  } = getTextFieldsProps()

  if (formData.loading || !ready) {
    return <Loader />
  }
  return (
    <>
      <BikeShop />

      <BikeDetails handleDecimalInput={handleDecimalInput} />

      <GenericField
        textFieldProps={helmetValueProps}
        inlineTextFieldProps={{
          onChange: (e) => handleDecimalInput(e),
        }}
        tooltip={t("helmetValueToolTip")}
      />

      <GenericField
        textFieldProps={otherAccessoriesValueProps}
        inlineTextFieldProps={{
          onChange: (e) => handleDecimalInput(e),
        }}
      />

      <Typography
        variant="body2"
        color="primary"
        sx={{ mt: 1, fontWeight: "bold" }}
      >
        {t("deliveryOrOtherFeesTitle")}
      </Typography>

      <Typography variant="body2" color="primary" sx={{ mb: "1.875rem" }}>
        {t("deliveryOrOtherFees")}
      </Typography>

      <TextField
        {...totalOrderValueProps}
        value={orderValueText}
        style={{ marginBottom: "1.875rem" }}
        sx={isExceedLimit ? exceedLimitStyles.sx : undefined}
        helperText={isExceedLimit ? exceedLimitStyles.helperText : undefined}
        error={isExceedLimit ? exceedLimitStyles.error : undefined}
      />
      <label htmlFor="totalOrderValue" style={{ display: "none" }}>
        {t("fieldLabels.totalOrderValue")}
      </label>

      {formData.brochureGroups.length > 1 && (
        <GenericSelect
          props={brochureGroupProps}
          inlineTextFieldProps={{
            label: brochureGroupLabel,
          }}
        >
          {formData.brochureGroups.map((brochureGroup) => {
            return (
              <MenuItem
                key={`brochure-group${brochureGroup.id}`}
                value={brochureGroup.id}
              >
                {brochureGroup.term.toString()}
              </MenuItem>
            )
          })}
        </GenericSelect>
      )}

      <UploadQuoteFiles />

      <Footnote />
      {calculatedResult && !isOverQuoteLimit && (
        <>
          <BikeQuote
            calculatedResult={calculatedResult}
            quoteType={quoteType}
          />
          {!isNetBikeShop && <BikeQuoteFootnote />}
        </>
      )}
    </>
  )
}

export default OrderDetails
