import { useEffect } from "react"
import { useParams } from "react-router-dom"
import i18n from "../../../i18n"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import { TopBanner } from "../../lifestyle-savings/homepage/TopBanner"
import { gtmOnPageland } from "../../shared/gtm-events/CustomGtmEvents"
import AboutCharities from "./AboutCharities"
import FourSimpleSteps from "./FourSimpleSteps"
import StartExploringPayRollGiving from "./StartExploringPayRollGiving"

const PayRollGivingLandingPage = () => {
  const params = useParams()
  useEffect(() => {
    gtmOnPageland("payroll_giving")
  })
  const payroll_giving_header = useBuildAwsImageUrl(
    i18n.language,
    "PayrollGivingHeader.png"
  )
  return (
    <div>
      <TopBanner
        desktopImageUrl={payroll_giving_header}
        mobileImageUrl={payroll_giving_header}
      />
      <StartExploringPayRollGiving
        link={`/organisations/${params.organisationId}/employee/benefits/payroll_giving/enter_payroll_giving_quote`}
      />
      <FourSimpleSteps
        link={`/organisations/${params.organisationId}/employee/benefits/payroll_giving/enter_payroll_giving_quote`}
      />
      <AboutCharities
        link={`/organisations/${params.organisationId}/employee/benefits/payroll_giving/enter_payroll_giving_quote`}
      />
    </div>
  )
}
export default PayRollGivingLandingPage
