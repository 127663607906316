import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Button, css, Grid, styled } from "@mui/material"
import NoticeBoardGrid from "./NoticeBoardGrid"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useState } from "react"
import { NoticeBoardItem } from "./BenefitManagementContext"
import AddSharpIcon from "@mui/icons-material/AddSharp"
import { RequestsToOpenContainer } from "./BenefitManagement"
import { useUpdatePositionsMutation } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import Alert from "../shared/Alert"

interface NoticeBoardReorderingProps {
  items?: NoticeBoardItem[]
  canEdit: boolean
}
const NoticeBoardReordering: React.FC<NoticeBoardReorderingProps> = ({
  items,
  canEdit,
}) => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [updatePositionsMutation] = useUpdatePositionsMutation()
  const params = useParams()
  const { t } = useTranslation(["clientAdmin", "clientAdminPage"])
  const [isEditing, setIsNotEditing] = useState(false)
  const [cards, setCards] = useState<NoticeBoardItem[]>(items || [])

  useEffect(() => {
    if (items) {
      setCards(items)
    }
  }, [items])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards]

      const dragId = updatedCards[dragIndex].position
      const hoverId = updatedCards[hoverIndex].position

      const draggedCard = updatedCards[dragIndex]
      updatedCards[dragIndex] = updatedCards[hoverIndex]
      updatedCards[hoverIndex] = draggedCard

      updatedCards[dragIndex] = {
        ...updatedCards[dragIndex],
        position: dragId,
      }
      updatedCards[hoverIndex] = {
        ...updatedCards[hoverIndex],
        position: hoverId,
      }

      return updatedCards
    })
  }, [])
  const handleClick = async () => {
    if (isEditing) {
      const { data } = await updatePositionsMutation({
        variables: {
          organisationId: params.organisationId ?? "",
          entityName: "LocalScheme",
          items: cards.map((card) => ({
            id: card.id,
            position: card.position,
          })),
        },
      })

      const errors = data?.updatePositions?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      }
    }

    setIsNotEditing(!isEditing)
  }

  const renderCard = useCallback(
    (card: NoticeBoardItem, index: number) => (
      <NoticeBoardGrid
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
        lock={false}
        isEditing={isEditing}
        enabled={card.enabled}
        canEdit={canEdit}
      />
    ),
    [moveCard, isEditing, canEdit]
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <RequestsToOpenContainer>
        {alertMessage && (
          <Alert
            severity={alertSeverity}
            message={alertMessage}
            key={errorCount}
          />
        )}
        <NoticeboardHeadingStyle>
          <h4>{t("clientAdmin:benefitManagement.noticeboards.heading")}</h4>
          {canEdit && (
            <Button
              variant="text"
              style={{ paddingRight: "1.25rem" }}
              onClick={handleClick}
              disabled={cards.length <= 1}
            >
              {!isEditing ? (
                <>
                  <ModeEditOutlineOutlinedIcon
                    sx={{ paddingRight: "0.5rem" }}
                  />
                  {t("clientAdminPage:clientAdminReordering.editLayout")}
                </>
              ) : (
                <>
                  <SaveOutlinedIcon sx={{ paddingRight: "0.5rem" }} />
                  {t("clientAdminPage:clientAdminReordering.saveLayout")}
                </>
              )}
            </Button>
          )}
        </NoticeboardHeadingStyle>
        <Grid container spacing={2}>
          {cards.map((card, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              {renderCard(card, i)}
            </Grid>
          ))}
          {!isEditing && canEdit && (
            <Grid item xs={12} sm={6} md={3}>
              <AddNoticeboardButton
                variant="outlined"
                href="add_a_new_noticeboard_tile"
              >
                <AddSharpIcon sx={{ width: "2.625rem", height: "2.625rem" }} />
                {t("clientAdmin:benefitManagement.noticeboards.addButton")}
              </AddNoticeboardButton>
            </Grid>
          )}
        </Grid>

        <br />
      </RequestsToOpenContainer>
    </DndProvider>
  )
}

export default NoticeBoardReordering

const AddNoticeboardButton = styled(Button)`
  ${({ theme }) => css`
    border: 2px solid ${theme.palette.primary.main};
    border-radius: 1rem;
    width: 100%;
    min-width: 9.75rem;
    max-width: 23.3125rem;
    height: 3.0625rem;
    text-align: center;
    font-weight: bold;
    padding: 0 3.2rem;
    line-height: normal;
    margin: 0.1875rem;

    &:hover {
      border: 2px solid ${theme.palette.primary.main};
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: unset;
      max-width: 16.25rem;
      height: 5rem;
      margin-left: 0;
    }
    &:hover {
      opacity: 0.6;
    }
  `}
`

export const NoticeboardHeadingStyle = styled("div")`
  ${({ theme }) => css`
    display: block;
    padding-bottom: 2rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  `}
`
