import {
  useMediaQuery,
  useTheme,
  Grid,
  Container,
  styled,
  css,
  Button as MuiButton,
} from "@mui/material"
import { useState } from "react"
import { ExploreButton } from "../../lifestyle-savings/shared-styles"
import { useTranslation } from "react-i18next"

export type BannerCarouselProps = {
  desktopImageUrl?: string | null
  mobileImageUrl?: string | null
  link: string | null | undefined
  title?: string
  subtitle?: string
  logos?: string[] | null
  text?: string | null
  welcomeText?: string | null
  showWelcomeText?: boolean
  BannerTitleComponent?: JSX.Element
  showButton?: boolean
  hi5SsoUrl?: string
}
export default function BannerCarousel({
  desktopImageUrl,
  mobileImageUrl,
  link,
  title,
  subtitle,
  logos,
  welcomeText,
  text,
  showWelcomeText,
  BannerTitleComponent,
  showButton,
  hi5SsoUrl,
}: BannerCarouselProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const bannerImageUrl = isDesktop
    ? desktopImageUrl || mobileImageUrl
    : mobileImageUrl || desktopImageUrl
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }

  const renderWelcomeText = () => {
    if (showWelcomeText && welcomeText && !logos) {
      return welcomeText
    }
    if (
      showWelcomeText &&
      !welcomeText &&
      !logos &&
      bannerImageUrl?.includes("store/Generic")
    ) {
      return (
        <>
          {t("banner.welcome")}
          <strong> {t("banner.health")}</strong>,
          <strong> {t("banner.wellbeing")} </strong> {t("banner.and")}
          <strong> {t("banner.happiness")} </strong>
        </>
      )
    }
    return null
  }
  const BannerSlide = (
    <>
      <div
        style={
          welcomeText || logos
            ? {
                backgroundColor: bannerImageUrl
                  ? theme.palette.primary.main
                  : theme.palette.white.main,
                backgroundImage: bannerImageUrl
                  ? `url("${bannerImageUrl}")`
                  : "",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }
            : {
                backgroundImage: bannerImageUrl
                  ? `url("${bannerImageUrl}")`
                  : "",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }
        }
        className="h-100 w-100"
      />

      {!logos && showWelcomeText && (
        <div className="landing_banner_welcome">
          <p className="welcomeText">{renderWelcomeText()}</p>
        </div>
      )}

      {showButton && (
        <div>
          <Button
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "30px",
            }}
            href={hi5SsoUrl}
            target="_blank"
            component={"a"}
          >
            {t("banner.exploreHigh5")}
          </Button>
        </div>
      )}
      {logos ? (
        <>
          <Container>
            <div className="landing_banner_welcome">
              {link ? (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener"
                  className="landing_layout"
                  onClick={(event) => {
                    const target = event.target as Element
                    if (target.closest(".read-toggle")) {
                      event.preventDefault()
                      event.stopPropagation()
                    }
                  }}
                >
                  <span className="screen-reader-only">
                    Banner link (opens in a new tab)
                  </span>
                  <div className="logo_container">
                    <Grid container justifyContent="center">
                      {logos?.map((logo, i) => (
                        <Grid item xs={12 / logos.length} key={i}>
                          {logo && (
                            <img
                              src={logo}
                              alt="vivup homepage banner"
                              className="logo"
                            />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  {text && (
                    <div>
                      {isDesktop ? (
                        <>
                          <p className="text">{text}</p>
                        </>
                      ) : (
                        <>
                          <div className="text-container">
                            <p
                              className="text"
                              style={{ fontSize: "0.6875rem" }}
                            >
                              {isReadMore ? text.slice(0, 120) : text}
                            </p>
                            {text.length > 120 && (
                              <div
                                className="read-toggle text"
                                onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  toggleReadMore()
                                }}
                              >
                                {isReadMore
                                  ? t("banner.readMore")
                                  : t("banner.readLess")}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </a>
              ) : (
                <Container className="landing_layout">
                  <div className="logo_container">
                    <Grid container justifyContent="center">
                      {logos?.map((logo, i) => {
                        return (
                          <Grid item xs={12 / logos.length} key={i}>
                            {logo && (
                              <img
                                src={logo}
                                alt="vivup homepage banner"
                                className="logo"
                              />
                            )}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </div>
                  {text && (
                    <div>
                      {isDesktop ? (
                        <>
                          <p className="text">{text}</p>
                        </>
                      ) : (
                        <>
                          <div className="text-container">
                            <p
                              className="text"
                              style={{ fontSize: "0.6875rem" }}
                            >
                              {isReadMore ? text.slice(0, 120) : text}
                            </p>
                            <div onClick={toggleReadMore} className="text">
                              {isReadMore
                                ? t("banner.readMore")
                                : t("banner.readLess")}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Container>
              )}
            </div>
          </Container>
        </>
      ) : (
        <>
          {text && (
            <div className="landing-banner">
              <p>{text}</p>
            </div>
          )}
          {(title || subtitle) && (
            <div className="landing-banner">
              {title && <h2 className="title">{title}</h2>}
              {subtitle && <h3 className="sub-title">{subtitle}</h3>}
            </div>
          )}
        </>
      )}
      {BannerTitleComponent && BannerTitleComponent}
    </>
  )
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener">
          {BannerSlide}
          <span className="screen-reader-only">
            Banner link (opens in a new tab)
          </span>
        </a>
      ) : (
        <>{BannerSlide}</>
      )}
    </>
  )
}
export const Button = styled(ExploreButton)`
  ${({ theme }) => css`
    && {
      display: flex;
      background-color: ${theme.palette.white.main};
      color: ${theme.palette.primary.main};
      margin: 0rem;
      max-width: 334px;
      width: 100%;
      height: 60px;
      font-size: 22px;
      font-weight: 700;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 175px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        height: 40px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  `}
` as typeof MuiButton
