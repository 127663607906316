import {
  Box,
  IconButton,
  TextField,
  css,
  styled,
  useTheme,
} from "@mui/material"
import useShrinkSearchBox from "./useShrinkSearchBox"
import { ArrowBackIos } from "@mui/icons-material"
import AlgoliaResults from "./AlgoliaResults"
import { UseSearchBoxProps, useHits, useSearchBox } from "react-instantsearch"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import useIsMobileView from "../../../utils/useIsMobileView"
import CancelIcon from "@mui/icons-material/Cancel"
import SearchIcon from "@mui/icons-material/Search"

export type SearchBoxProps = {
  isWidgetOpen: boolean
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  // gtmEventType: string
}

function AlgoliaSearchBox({ isWidgetOpen, setIsWidgetOpen }: SearchBoxProps) {
  const [isShrunk] = useShrinkSearchBox()
  const theme = useTheme()
  const isMobile = useIsMobileView()

  const closeWidget = () => {
    setIsWidgetOpen(false)
  }

  // keyboard navigation code starts here
  const [combinedLength, setCombinedLength] = useState(0)
  const listRef = useRef<Array<HTMLElement>>([])
  const [isLastStrokeEscape, setIsLastStrokeEscape] = useState(false)

  const [focusIndex, setFocusIndex] = useState<undefined | null | number>(null)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()
  const searchPath = `/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/products`
  const paramName = "search"
  const [query, setQuery] = useState(searchParams.get(paramName) || "")
  const brochureGroup = searchParams.get("bg")

  const handleSubmit = (query: string) => {
    if (query.length > 0) {
      setIsWidgetOpen(false)
      if (window.location.pathname === searchPath) {
        setSearchParams(
          brochureGroup
            ? { [paramName]: query, bg: brochureGroup }
            : { [paramName]: query }
        )
        // gtmSearch(value)
        // gtmEventType === "lifestyleSavings" && gtmLifestyleSavingsSearch(value)
      } else {
        // gtmEventType === "lifestyleSavings" && gtmLifestyleSavingsSearch(value)
        const newSearchPath = `${searchPath}?${paramName}=${encodeURIComponent(
          query
        )}${brochureGroup ? `&bg=${encodeURIComponent(brochureGroup)}` : ""}`

        navigate(newSearchPath)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<EventTarget>) => {
    const element = e.target as HTMLElement
    if (
      e.key === "Enter" &&
      (element.id === "search" ||
        element.getAttribute("aria-label") === "search button")
    ) {
      handleSubmit(query)
    }

    if (e.key === "Escape") {
      setIsWidgetOpen(false)
      setFocusIndex(0) // focus on inputField
      setIsLastStrokeEscape(true)
    }

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      // prevent downward scrolling
      e.preventDefault()

      if (isLastStrokeEscape) {
        setIsWidgetOpen(true)
        setIsLastStrokeEscape(false)
      }

      if (e.key === "ArrowDown") {
        setFocusIndex((prev) => {
          // considering the input field has the index 0, the start of suggestions is on index {1}
          if (typeof prev === "number") {
            const nextIndex =
              prev + 1 > listRef.current.length - 1 ? 0 : prev + 1
            return nextIndex
          }
        })
      }

      if (e.key === "ArrowUp") {
        setFocusIndex((prev) => {
          if (typeof prev === "number") {
            return prev - 1 < 0 ? listRef.current.length - 1 : prev - 1
          }
        })
      }
    }
  }

  useEffect(() => {
    listRef.current = listRef.current.slice(0, combinedLength + 1)
    if (typeof focusIndex === "number") {
      listRef.current[focusIndex].focus()
    }
  }, [focusIndex, listRef, combinedLength])

  // original inputField functions begins here

  const debounceInterval = 500
  const timerId = useRef<number | null>(null)

  const queryHook: UseSearchBoxProps["queryHook"] = useCallback(
    (query: string, search: (value: string) => void) => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }

      timerId.current = window.setTimeout(() => {
        if (query.length > 1) {
          search(query)
        }
      }, debounceInterval)
    },
    []
  )

  const inputFieldIndex = 0

  const { refine, clear } = useSearchBox({ queryHook })

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setIsLastStrokeEscape(false)
    setIsWidgetOpen(true)
    setQuery(e.target.value)
    refine(e.target.value)

    if (!e.target.value) {
      clear()
    }
  }

  const clearQuery = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setQuery("")
    clear()
    document.getElementById("search")?.focus()
    e.stopPropagation()
  }

  const handleInputFieldClick = () => {
    setIsLastStrokeEscape(false)
    setIsWidgetOpen(true)
    setFocusIndex(inputFieldIndex)
  }

  const handleInputFieldFocus = () => {
    setIsWidgetOpen(true)
    setFocusIndex(inputFieldIndex)
  }

  // set the focus index to the inputField
  useEffect(() => {
    if (isMobile && isWidgetOpen) {
      setFocusIndex(inputFieldIndex)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { hits, results } = useHits()

  return (
    <>
      <div
        className={`search-box ${
          isMobile && isShrunk ? "condensedSeachBox" : "defaultSeachBox"
        }`}
        id="search-box"
      >
        <label htmlFor="search">
          <span className="d-none">Search</span>
        </label>
        {/* back button on mobile search overlay */}
        {isMobile && isWidgetOpen && (
          <IconButton
            sx={{ width: "44px" }}
            onClick={closeWidget}
            aria-label="close overlay search modal button"
          >
            <ArrowBackIos
              color="primary"
              sx={{ height: "16.67px", fontWeight: "bold" }}
            />
          </IconButton>
        )}
        <StyledTextField
          id="search"
          placeholder="Search for products, brands and more..."
          variant="outlined"
          role="search"
          title="Search box"
          autoComplete="off"
          fullWidth={false}
          value={query}
          onFocus={() => handleInputFieldFocus()}
          onClick={() => handleInputFieldClick()}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e: React.KeyboardEvent<EventTarget>) => handleKeyDown(e)}
          inputRef={(e) => (listRef.current[inputFieldIndex] = e)}
          inputProps={{
            style: { fontSize: "16px" },
          }}
          sx={{
            input: {
              "&::placeholder": {
                opacity: 0.7,
              },
            },
            "&:hover fieldset": {
              borderColor: theme.palette.secondary.main + " !important",
            },
            fieldset: {
              borderColor: theme.palette.secondary.main + " !important",
            },
          }}
          InputProps={{
            sx: {
              paddingRight: 0,
            },
            endAdornment: (
              <>
                {query && (
                  <IconButton
                    size="small"
                    onClick={(e) => clearQuery(e)}
                    aria-label="clear search word button"
                    data-element="search"
                  >
                    <CancelIcon
                      fontSize="small"
                      className="search-box__delete-btn"
                      sx={{ color: theme.palette.grey[700] }}
                    />
                  </IconButton>
                )}
                <IconButton
                  sx={{ padding: 0 }}
                  aria-label="search button"
                  data-element="search"
                  onClick={(e) => {
                    handleSubmit(query)
                    e.stopPropagation()
                  }}
                >
                  <SearchIcon
                    className="search-box__btn"
                    id="search-icon"
                    sx={{ backgroundColor: theme.palette.secondary.main }}
                  />
                </IconButton>
              </>
            ),
          }}
        />
        {/* desktop open search widget */}
        {!isMobile && isWidgetOpen && (
          <DesktopWidgetContainer>
            <InnerContainer>
              <AlgoliaResults
                setIsWidgetOpen={setIsWidgetOpen}
                handleKeyDown={handleKeyDown}
                listRef={listRef}
                setCombinedLength={setCombinedLength}
                hits={hits}
                results={results}
                searchQuery={query}
              />
            </InnerContainer>
          </DesktopWidgetContainer>
        )}
      </div>
      {isMobile && isWidgetOpen && (
        <AlgoliaResults
          setIsWidgetOpen={setIsWidgetOpen}
          handleKeyDown={handleKeyDown}
          listRef={listRef}
          setCombinedLength={setCombinedLength}
          hits={hits}
          results={results}
          searchQuery={query}
        />
      )}
    </>
  )
}

export default memo(AlgoliaSearchBox, (prevProps, nextProps) => {
  return prevProps.isWidgetOpen === nextProps.isWidgetOpen
})

const DesktopWidgetContainer = styled(Box)`
  ${({ theme }) => css`
    position: absolute;
    background-color: ${theme.palette.white.main};
    top: 3rem;
    z-index: 1300;
    box-shadow: ${theme.boxShadow};
    width: 117.2%;
    left: -8.6%;
    border-radius: ${theme.boxBorderRadius};
  `}
`

const InnerContainer = styled(Box)`
  width: 100%;
  min-height: 23.25rem;
`

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.secondary.main};
  }

  && {
    height: 100%;
  }
`
