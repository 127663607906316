import { useEffect } from "react"
import { useTranslation, Trans } from "react-i18next"
import Layout from "./Layout"
import {
  Container,
  Header,
  InlineLinks,
  InlineLinksText,
  ListContainer,
  Paragraph,
  StyledUnorderedList,
} from "./StyledComponents"

const TermsConditions = () => {
  const { t, ready } = useTranslation("termsConditions")

  useEffect(() => {
    if (ready) document.title = `Vivup: ${t("title")}`
  }, [t, ready])

  if (!ready) {
    return null
  }
  return (
    <Layout bannerText={t("title")}>
      <Container data-cy="terms-conditions-page">
        {/* Intro */}
        <Paragraph>
          <Trans
            i18nKey="termsConditions:intro.text"
            components={{
              siteLink: <InlineLinks href={t("intro.siteUrl")} />,
            }}
            values={{
              siteUrl: t("intro.siteUrl"),
            }}
          />
        </Paragraph>

        {/* Registration */}
        <Header variant="h3">{t("registration.title")}</Header>
        <Paragraph>{t("registration.text1")}</Paragraph>
        <Paragraph>{t("registration.text2")}</Paragraph>
        <Paragraph>{t("registration.text3")}</Paragraph>

        <Paragraph>{t("registration.warranties.intro")}</Paragraph>
        <ListContainer>
          <Trans
            i18nKey="termsConditions:registration.warranties.items"
            components={{
              list: <StyledUnorderedList />,
              item: <li />,
            }}
          />
        </ListContainer>

        <Paragraph>{t("registration.text4")}</Paragraph>
        <Paragraph>{t("registration.text5")}</Paragraph>
        <Paragraph>{t("registration.text6")}</Paragraph>
        <Paragraph>{t("registration.text7")}</Paragraph>
        <Paragraph>{t("registration.text8")}</Paragraph>

        {/* Contact Details */}
        <Header variant="h3">{t("contact.title")}</Header>
        <Paragraph>
          <Trans
            i18nKey="termsConditions:contact.text"
            components={{
              emailLink: (
                <InlineLinksText href={`mailto:${t("contact.email")}`} />
              ),
              phoneLink: <InlineLinksText href={`tel:${t("contact.phone")}`} />,
            }}
            values={{
              email: t("contact.email"),
              phone: t("contact.phone"),
            }}
          />
        </Paragraph>
        <Paragraph>{t("contact.callInfo")}</Paragraph>

        {/* Other Terms */}
        <Header variant="h3">{t("otherTerms.title")}</Header>
        <Paragraph>{t("otherTerms.intro")}</Paragraph>
        <ListContainer>
          <Trans
            i18nKey="termsConditions:otherTerms.items"
            components={{
              list: <StyledUnorderedList />,
              item: <li />,
            }}
          />
        </ListContainer>
        <Paragraph>{t("otherTerms.additional")}</Paragraph>
        <Paragraph>{t("otherTerms.text1")}</Paragraph>
        <Paragraph>{t("otherTerms.text2")}</Paragraph>
        <Paragraph>{t("otherTerms.text3")}</Paragraph>

        {/* Material Usage */}
        <Header variant="h3">{t("materialUsage.title")}</Header>
        <Paragraph>{t("materialUsage.text1")}</Paragraph>
        <Paragraph>{t("materialUsage.text2")}</Paragraph>

        {/* Recommendations */}
        <Header variant="h3">{t("recommendations.title")}</Header>
        <Paragraph>{t("recommendations.text1")}</Paragraph>

        {/* Liability */}
        <Header variant="h3">{t("liability.title")}</Header>
        <Paragraph>{t("liability.text1")}</Paragraph>
        <Paragraph>{t("liability.text2")}</Paragraph>

        {/* Personal Information */}
        <Header variant="h3">{t("personalInfo.title")}</Header>
        <Paragraph>{t("personalInfo.text")}</Paragraph>

        {/* Jurisdiction */}
        <Header variant="h3">{t("jurisdiction.title")}</Header>
        <Paragraph>{t("jurisdiction.text")}</Paragraph>
        <Paragraph sx={{ marginBottom: "0.1rem" }}>
          {t("versionControl.Version")}
        </Paragraph>
        <Paragraph>{t("versionControl.lastUpdated")}</Paragraph>
      </Container>
    </Layout>
  )
}

export default TermsConditions
