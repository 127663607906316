import { useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router"
import { IconButton, useMediaQuery, useTheme } from "@mui/material"
import TextField from "@mui/material/TextField"
import SearchIcon from "@mui/icons-material/Search"
import {
  gtmSearch,
  gtmLifestyleSavingsSearch,
} from "../gtm-events/CustomGtmEvents"
import { SearchWidget } from "./SearchWidget"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import useShrinkSearchBox from "./useShrinkSearchBox"
import CancelIcon from "@mui/icons-material/Cancel"
import useFocusSuggestion from "./useFocusSuggestion"

export type LsSearchBoxProps = {
  paramName?: string
  placeholder?: string
  isWidgetOpen: boolean
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchPath: string
  gtmEventType: string
  suggestions: string[]
}

function LsSearchBox({
  paramName = "search",
  placeholder = "Search for products, brands and more...",
  searchPath,
  suggestions,
  isWidgetOpen,
  setIsWidgetOpen,
  gtmEventType,
}: LsSearchBoxProps) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get(paramName) || "")
  const [isShrunk] = useShrinkSearchBox()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down("md"))

  const handleSubmit = () => {
    if (value.length > 0) {
      setIsWidgetOpen(false)
      if (window.location.pathname === searchPath) {
        setSearchParams({ [paramName]: value })
        gtmSearch(value, undefined)
        gtmEventType === "lifestyleSavings" && gtmLifestyleSavingsSearch(value)
      } else {
        gtmEventType === "lifestyleSavings" && gtmLifestyleSavingsSearch(value)
        navigate(searchPath + `?${paramName}=` + encodeURIComponent(value))
      }
    }
  }

  const handleClickAway = () => {
    setIsWidgetOpen(false)
  }

  const [focusIndex, setFocusIndex] = useState<undefined | null | number>(null)
  const [isLastStrokeEscape, setIsLastStrokeEscape] = useState(false)
  const suggestionTextDefault = undefined
  const [suggestionText, setSuggestionText] = useState<undefined | string>(
    suggestionTextDefault
  )
  const inputFieldIndex = 0
  const listRef = useRef<Array<HTMLDivElement>>([])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Enter") {
      handleSubmit()
    }

    if (e.code === "Escape") {
      setIsWidgetOpen(false)
      setFocusIndex(0)
      setIsLastStrokeEscape(true)
    }

    if (e.code === "Tab") {
      setIsLastStrokeEscape(false)
      // when tab away while keyboard navigating widget, set the value to suggestionText so it persists in the searchBox
      suggestionText && setValue(suggestionText)
    }

    if (e.code === "ArrowDown" || e.code === "ArrowUp") {
      // prevent downward scrolling
      e.preventDefault()

      if (isLastStrokeEscape) {
        setIsWidgetOpen(true)
        setIsLastStrokeEscape(false)
      }

      if (e.code === "ArrowDown") {
        setFocusIndex((prev) => {
          // considering the input field has the index 0, the start of suggestions is on index {1}
          if (typeof prev === "number") {
            return prev + 1 > suggestions.length ? 0 : prev + 1
          }
        })
      }

      if (e.code === "ArrowUp") {
        setFocusIndex((prev) => {
          if (typeof prev === "number") {
            return prev - 1 < 0 ? suggestions.length : prev - 1
          }
        })
      }
    }
  }

  const handleInputFieldClick = () => {
    setIsLastStrokeEscape(false)
    setIsWidgetOpen(true)
    setFocusIndex(0)
  }

  const handleInputFieldFocus = () => {
    setFocusIndex(0)
    if (isLastStrokeEscape) {
      setIsWidgetOpen(false)
      setFocusIndex(0)
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsLastStrokeEscape(false)
    setIsWidgetOpen(true)
    setValue(e.target.value)
  }

  const handleClearValue = () => {
    setValue("")
    // put the focus back to the input field after clearing input
    if (typeof focusIndex === "number") {
      listRef.current[inputFieldIndex].focus()
    }
  }

  // set which element in the search widget to focus on when `focusIndex` has changed
  useFocusSuggestion({ focusIndex, listRef, setSuggestionText })

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={`search-box ${
          !isDesktop && isShrunk ? "condensedSeachBox" : "defaultSeachBox"
        }`}
        id="search-box-ls"
      >
        <label htmlFor="ls-search">
          <span className="d-none">Search</span>
        </label>
        <TextField
          id="ls-search"
          placeholder={placeholder}
          variant="outlined"
          role="search"
          title="Search box"
          value={suggestionText ?? value}
          autoComplete="off"
          onChange={(e) => handleInputChange(e)}
          onFocus={() => handleInputFieldFocus()}
          inputRef={(e) => (listRef.current[inputFieldIndex] = e)}
          inputProps={{
            maxLength: 512,
            style: { fontSize: "16px" },
          }}
          fullWidth={false}
          onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e)}
          onClick={() => handleInputFieldClick()}
          sx={{
            input: {
              "&::placeholder": {
                opacity: 0.7,
              },
            },
            "&:hover fieldset": {
              borderColor: theme.palette.secondary.main + " !important",
            },
            fieldset: {
              borderColor: theme.palette.secondary.main + " !important",
            },
          }}
          InputProps={{
            sx: {
              paddingRight: "0px",
              height: "48px",
              fontSize: "16px",
            },
            endAdornment: (
              <>
                {value && (
                  <IconButton
                    size="small"
                    onClick={() => handleClearValue()}
                    data-element="search"
                  >
                    <CancelIcon
                      fontSize="small"
                      className="search-box__delete-btn"
                      sx={{ color: theme.palette.grey[700] }}
                    />
                    <div className="screen-reader-only">Clear</div>
                  </IconButton>
                )}
                <IconButton sx={{ padding: 0 }} data-element="search">
                  <SearchIcon
                    className="search-box__btn"
                    onClick={() => {
                      handleSubmit()
                    }}
                    sx={{ backgroundColor: theme.palette.secondary.main }}
                  />
                  <div className="screen-reader-only">Search</div>
                </IconButton>
              </>
            ),
          }}
        />
        {isWidgetOpen && !isLastStrokeEscape && (
          <SearchWidget
            searchPath={searchPath}
            suggestionList={suggestions}
            paramName={paramName}
            setValue={setValue}
            setIsWidgetOpen={setIsWidgetOpen}
            listRef={listRef}
            handleKeyDown={handleKeyDown}
          />
        )}
      </div>
    </ClickAwayListener>
  )
}

export default LsSearchBox
