import { Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useMediaQuery, useTheme } from "@mui/material"
import Dropdown from "../../Dropdown"
import StoreBreadcrumbs from "../StoreBreadcrumbs"
import CircularProgress from "@mui/material/CircularProgress"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export type TopbarProps = {
  showTopFilters: boolean
  isLifestyleFilters: boolean
  totalEntries: number
  loading: boolean
  showSortBy?: boolean
  isLocalBenefits?: boolean
  schemeType?: boolean
}
const Topbar = ({
  showTopFilters,
  isLifestyleFilters,
  totalEntries,
  loading,
  showSortBy = true,
  isLocalBenefits = false,
  schemeType,
}: TopbarProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("topBar")
  const [searchParams] = useSearchParams()
  const resultsPerPage = searchParams.get("results_per_page") || "40"
  const sortBy = searchParams.get("sort_by") || "popularity"

  let currentPage = Number(searchParams.get("page"))
  currentPage = currentPage === 0 ? 1 : currentPage

  const resultsPerPageNumber = Number(resultsPerPage)

  const currentShowingNumber = ` ${
    currentPage === 1 ? 1 : (currentPage - 1) * resultsPerPageNumber + 1
  } - ${
    currentPage * resultsPerPageNumber < totalEntries
      ? currentPage * resultsPerPageNumber
      : totalEntries
  } of ${totalEntries} `

  const dropdownOptions = isLifestyleFilters
    ? [
        {
          title: t("isLifestylefilterText.mostPopularText"),
          value: "popularity",
        },
        { title: t("isLifestylefilterText.NewestText"), value: "newest" },
        { title: t("isLifestylefilterText.a_to_z_Text"), value: "name_asc" },
        {
          title: t("isLifestylefilterText.z_to_a_Text"),
          value: "name_desc",
        },
      ]
    : [
        { title: t("sortByFilterValue.PopularityText"), value: "popularity" },
        { title: t("sortByFilterValue.NewestText"), value: "newest" },
        {
          title: t("sortByFilterValue.priceHighestText"),
          value: "price_desc",
        },
        { title: t("sortByFilterValue.priceLowestText"), value: "price_asc" },
        { title: t("sortByFilterValue.a_to_z_Text"), value: "name_asc" },
        { title: t("sortByFilterValue.z_to_a_Text"), value: "name_desc" },
      ]

  if (!ready || loading) {
    return (
      <div
        className="content-center"
        style={
          isDesktop
            ? { width: "100%", height: "85.352px" }
            : { width: "100%", height: "166.391px" }
        }
      >
        <CircularProgress
          size="1.25rem"
          sx={{ marginRight: "0.75rem" }}
          aria-label="Circular progress animation"
        />
        {t("topBar.loadingText")}
      </div>
    )
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <StoreBreadcrumbs
          showTopFilters={showTopFilters}
          isLifestyleFilters={isLifestyleFilters}
          isLocalBenefits={isLocalBenefits}
        />
        {isDesktop ? (
          <>
            <Grid
              item
              md={4}
              sx={{ textAlign: "center", color: "primary.main" }}
              data-cy="topbar-results"
            >
              <Typography sx={{ margin: 0, color: "primary.main" }}>
                {t("topBar.showingText")}
                <strong>
                  {totalEntries === 0 ? " 0 " : currentShowingNumber}
                </strong>
                {t("topBar.resultsText")}
              </Typography>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid item xs={7} md={4} lg={4}>
          <Stack
            direction="row"
            justifyContent={
              isDesktop
                ? isLocalBenefits
                  ? "flex-end"
                  : "space-evenly"
                : "flex-end"
            }
            flexWrap="wrap"
          >
            {isDesktop && (
              <>
                <Dropdown
                  paramName="results_per_page"
                  label={t("topBar.resultPageLabel")}
                  dropdownWidth="3.9rem"
                  selected={resultsPerPage}
                  dropdownOptions={[
                    { title: "40", value: "40" },
                    { title: "80", value: "80" },
                    { title: "120", value: "120" },
                  ]}
                />
              </>
            )}

            {showTopFilters && showSortBy ? (
              <>
                <Dropdown
                  paramName="sort_by"
                  label={t("topBar.sortByText")}
                  selected={sortBy}
                  dropdownOptions={dropdownOptions}
                />
              </>
            ) : (
              ""
            )}
          </Stack>
        </Grid>

        {isDesktop ? (
          ""
        ) : (
          <>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: schemeType ? "1.25rem" : "2.25rem",
                  marginTop: "1rem",
                  color: "primary.main",
                }}
              >
                {t("topBar.showingText")}
                <strong>
                  {totalEntries === 0 ? " 0 " : currentShowingNumber}
                </strong>
                {t("topBar.resultsText")}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default Topbar
