import { Box, css, styled } from "@mui/material"
import { Button } from "./Ofsted"
import { ButtonContainer } from "./FamilyCare"
import { SecondaryContainer } from "./benefitSharedStyles"
import { useTranslation } from "react-i18next"
export interface FamilyCareProps {
  link: string
}

const StartExploringFamilyCare: React.FC<FamilyCareProps> = ({ link }) => {
  const { t, ready } = useTranslation("familyCareLanding")
  if (!ready) {
    return null
  }
  return (
    <MainContainer>
      <SecondaryContainer>
        <div>
          <div style={{ paddingBottom: "1.25rem" }}>
            {t("familyCareLanding.familyCareBenefit")}
          </div>
          <div style={{ paddingBottom: "1.25rem" }}>
            {t("familyCareLanding.ifYouNeed")}
          </div>
          <div style={{ paddingBottom: "1.25rem" }}>
            {t("familyCareLanding.safeHands")}
          </div>
          <ButtonContainer>
            <Button
              variant="gradient"
              href={link}
              component={"a"}
              aria-label="Start Exploring"
              data-cy="start-exploring-btn"
            >
              {t("familyCareLanding.button")}
            </Button>
          </ButtonContainer>
        </div>
      </SecondaryContainer>
    </MainContainer>
  )
}
export default StartExploringFamilyCare
const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 19.375rem;
    background-color: ${theme.palette.white.main};
    display: flex;
    justify-content: flex-start;
    color: #35185f;
    align-items: center;
    flex-direction: column;
    padding-top: 3.125rem;

    /* Tablets (max-width: 959px) */
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-height: 34.375rem;
      width: unset;
      justify-content: flex-start;
    }
    /* Mobile devices (max-width: 599px) */
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 31.25rem;
      height: 100%;
      width: unset;
      justify-content: flex-start;
    }
  `}
`

export const ButtonContainerText = styled(ButtonContainer)`
  margin-top: 0.625rem;
`
