import {
  useBasketData,
  BasketContext,
  updateBasketItemsBrochureGroup,
} from "./BasketContext"
import { default as BasketItem } from "./Item"
import { BasketItem as BasketItemType } from "../../../graphqGenaretedTypes"
import EmptyBasket from "./EmptyBasket"
import { Container, Button, Grid, styled, css } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import InfoTooltip from "../../shared/InfoTooltip"
import { MAX_BASKET_QTY } from "../../../data/constants"
import classNames from "classnames"
import Loader from "../../shared/Loader"
import { useNavigate } from "react-router"
import { useContext, useEffect, useState } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { getDynamicInfoToolTip } from "../../../utils/dynamicMessageSchemeStores"
import { useParams } from "react-router-dom"
import StoreDropdown from "../../shared/StoreDropdown"
import SavingsBanner from "../SavingsBanner"
import snakeToCamel from "../../../utils/snakeToCamel"
import { useTranslation } from "react-i18next"
import { updateSalaryBracketModalState } from "../../../utils/apolloClient"
import LimitationMessages from "./LimitationMessage"
import ExceedLimitModal from "./ExceedLimitModal"
import { ColorStrip } from "../StoreHome"
import TopBenefitWindowInfo from "../TopBenefitWindowInfo"
import { gtmOnPageland } from "../../shared/gtm-events/CustomGtmEvents"

const savingBannerProps = {
  marginLeft: { xs: "-1rem", sm: "-1.25rem" },
  marginRight: { xs: "-1rem", sm: "-1.25rem" },
  marginBottom: "1rem",
  width: { xs: "calc(100% + 2rem)", sm: "calc(100% + 2.5rem)" },
  borderRadius: 0,
}

export default function Basket() {
  const { data } = useBasketData()
  const theme = useTheme()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { quoteType, currentUserId } = useContext(StoreLayoutContext)
  const isNet = quoteType == "NET"
  const params = useParams()
  const currentOrg = params.organisationId
  const [brochureGroupId, setBrochureGroupId] = useState("")
  const { t, ready } = useTranslation(["constants", "basket"])
  const { windowStatus } = useContext(StoreLayoutContext)
  const navigateToStoreHome = () => {
    navigate(
      `/organisations/${currentOrg}/employee/benefits/${params.schemeType}/landing`
    )
  }

  useEffect(() => {
    updateSalaryBracketModalState("basket")
  })

  useEffect(() => {
    const currentBrochureGroupId =
      data.brochureGroups.filter(
        (brochureGroup) => brochureGroup.term === data.term
      )[0]?.id || ""
    if (currentBrochureGroupId !== brochureGroupId) {
      setBrochureGroupId(currentBrochureGroupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.term])
  useEffect(() => {
    gtmOnPageland(`basket${params.schemeType}`)
  }, [params.schemeType])
  const [updateBrochureGroup] = updateBasketItemsBrochureGroup(
    data.id,
    brochureGroupId
  )
  useEffect(() => {
    currentUserId &&
      window.localStorage.setItem("customerUserId", String(currentUserId))
    currentOrg && window.localStorage.setItem("currentOrg", currentOrg)
  })
  useEffect(() => {
    if (
      brochureGroupId !== "" &&
      data.products.length > 0 &&
      brochureGroupId !== data.products[0].brochureGroupId
    ) {
      updateBrochureGroup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brochureGroupId])

  const basketItemCount = data.products.reduce(
    (result: number, current: BasketItemType) => current.quantity + result,
    0
  )

  const [modalOpen, setModalOpen] = useState(false)

  const showLimitationMessage = Object.keys(data.limitationMessage).length > 0

  const limitExceeded =
    (showLimitationMessage &&
      data.limitationMessage.translation ===
        "totalBenefitOrderLimitExceeded") ||
    data.limitationMessage.translation === "benefitActiveOrdersExceeded"

  if (!ready) {
    return null
  }
  return (
    <BasketContext.Provider value={data}>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          {Object.keys(data.limitationMessage).length > 0 && (
            <ExceedLimitModal
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
              headroom={data.headroomValue}
              limitationMessage={data.limitationMessage}
            />
          )}
          {windowStatus.status === "windowActive" && (
            <ColorStrip>
              <TopBenefitWindowInfo windowStatus={windowStatus} />
            </ColorStrip>
          )}
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={9.665}
              sx={{
                marginX: "auto",
              }}
            >
              <Grid item xs={12}>
                <div
                  className="basket-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className={classNames(
                      "basket-container__title",
                      isDesktop ? "h2" : "h3"
                    )}
                  >
                    {t("basket:basket.basketTitle")}
                  </div>
                  {brochureGroupId !== "" && data.brochureGroups.length > 1 && (
                    <StoreDropdown
                      dropdownOptions={data.brochureGroups.map(
                        (brochureGroup) => ({
                          value: brochureGroup.id,
                          title:
                            brochureGroup.term +
                            " " +
                            t("basket:basket.monthTerm"),
                        })
                      )}
                      selected={brochureGroupId}
                      onChange={(result) => {
                        setBrochureGroupId(result)
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          {data.products.length > 0 ? (
            <>
              <Container sx={{ marginBottom: "10rem" }}>
                <Grid
                  item
                  container
                  xs={12}
                  lg={9.665}
                  sx={{
                    marginX: "auto",
                  }}
                >
                  <Grid item xs={12}>
                    <div className="basket-container" style={{ paddingTop: 0 }}>
                      <div className="basket-container__items">
                        {data.products.map((product) => (
                          <BasketItem
                            key={product.id}
                            product={product}
                            maxQuantity={MAX_BASKET_QTY}
                            editable={true}
                            currentUserId={currentUserId?.toString()}
                            currentOrg={currentOrg}
                            status={
                              (snakeToCamel(product.status) as
                                | "preOrder"
                                | "backOrder") || "default"
                            }
                            releaseDate={product.releaseDate || undefined}
                          />
                        ))}
                      </div>
                      <div
                        className={classNames(
                          "basket-container__totals color-primary",
                          { "border-bottom": isDesktop }
                        )}
                        style={{
                          paddingBottom:
                            params.schemeType === "cycle_to_work" && !isDesktop
                              ? "0.5rem"
                              : "",
                        }}
                      >
                        <div className="basket-container__totals-title">
                          {t("basket:basket.orderTotal")}
                        </div>
                        <Grid
                          item
                          xs={12}
                          container
                          sx={{
                            paddingBottom: !data.endOfAgreementFee
                              ? "1rem"
                              : "",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {t("basket:basket.totalAmount")} (
                            {basketItemCount + " "}
                            {basketItemCount === 1
                              ? t("basket:basket.item")
                              : t("basket:basket.items")}
                            ):
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              className={classNames(
                                { "basket-container__price": !isNet },
                                isDesktop ? "h2" : "h3"
                              )}
                              style={{
                                marginRight:
                                  params.schemeType !== "bike_shop"
                                    ? ""
                                    : "0rem",
                                color: theme.palette.secondary.main,
                              }}
                              data-cy="total-price"
                            >
                              £{data.monthlyPrice.toFixed(2)}
                              {!isNet && params.schemeType !== "bike_shop" ? (
                                <InfoTooltip
                                  content={getDynamicInfoToolTip(quoteType)}
                                />
                              ) : (
                                ""
                              )}
                            </Grid>
                            <div style={{ textAlign: "right" }}>
                              {t("basket:basket.perMonths")} {data.term}{" "}
                              {t("basket:basket.months")}
                            </div>
                            {data.disposalFee && (
                              <div
                                className="basket-container__price"
                                style={{ textAlign: "right" }}
                              >
                                {t("basket:basket.disposal")}{" "}
                                {t("basket:basket.currency")}
                                {data.disposalFee}
                                <InfoTooltip
                                  content={t("constants.disposalFeeToolTip")}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {isDesktop &&
                        params.schemeType === "cycle_to_work" &&
                        data.endOfAgreementFee &&
                        data.endOfAgreementFee > 0 ? (
                          <div
                            className="basket-container__price"
                            style={{ textAlign: "right" }}
                          >
                            {t("basket:basket.endOfAgreement")}{" "}
                            {t("basket:basket.currency")}
                            {data.endOfAgreementFee?.toFixed(2)}
                            <InfoTooltip
                              content={t("constants.endOfAgreementFeeTooltip")}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {!isDesktop &&
                        params.schemeType === "cycle_to_work" &&
                        data.endOfAgreementFee &&
                        data.endOfAgreementFee > 0 ? (
                          <Grid xs={12} sx={{ display: "flex" }}>
                            <Grid xs={6} style={{ paddingTop: "0.6rem" }}>
                              {t("basket:basket.endOfAgreement")}
                            </Grid>
                            <Grid
                              xs={6}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span style={{ paddingTop: "0.6rem" }}>
                                {t("basket:basket.currency")}
                                {data.endOfAgreementFee?.toFixed(2)}
                              </span>
                              <InfoTooltip
                                content={t(
                                  "constants.endOfAgreementFeeTooltip"
                                )}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <>
                          {isDesktop ? (
                            ""
                          ) : (
                            <>
                              {params.schemeType === "cycle_to_work" ? (
                                <>
                                  {t("basket:basket.totalMonthlyReduction")}{" "}
                                  {t("basket:basket.currency")}
                                  {data.monthlyGrossAmount.toFixed(2)}{" "}
                                  {t("basket:basket.seenOnPaySlip")}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}{" "}
                        </>
                      </div>
                      <div className="basket-container__totals">
                        {isDesktop ? (
                          <Grid container>
                            <Grid item xs={6}>
                              {!isNet && params.schemeType !== "bike_shop"
                                ? t("basket:basket.totalMonthlyReduction")
                                : t("basket:basket.monthlyDeduction")}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                              }}
                            >
                              <div>
                                {t("basket:basket.currency")}
                                {data.monthlyGrossAmount.toFixed(2)}
                              </div>
                              <div>{t("basket:basket.seenOnPaySlip2")}</div>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            {params.schemeType == "cycle_to_work" ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                {!isNet && params.schemeType !== "bike_shop"
                                  ? "Total gross monthly reduction:"
                                  : "Total monthly deduction:"}{" "}
                                £{data.monthlyGrossAmount.toFixed(2)}{" "}
                                {t("basket:basket.seenOnPaySlip")}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {!isDesktop && data.totalSavings && (
                        <SavingsBanner
                          savings={data.totalSavings}
                          label={t("basket:basket.label")}
                          orderValue={data.totalRrp.toString()}
                          brochureGroup={brochureGroupId.toString()}
                          savingButtonProps={savingBannerProps}
                        />
                      )}
                      {isDesktop && (
                        <ButtonContainer>
                          <Button
                            variant="gradient"
                            disabled={limitExceeded ?? false}
                            sx={{ width: "17.5rem" }}
                            onClick={() => {
                              navigate("../checkout")
                            }}
                          >
                            {t("basket:basket.continueOrder")}
                          </Button>
                          <ContinueShoppingButton
                            tabIndex={0}
                            role="button"
                            onKeyDown={(event) => {
                              if (event.key === "Enter" || event.key === " ")
                                navigateToStoreHome()
                            }}
                            onClick={navigateToStoreHome}
                          >
                            &lt; {t("basket:basket.continueShopping")}
                          </ContinueShoppingButton>
                        </ButtonContainer>
                      )}

                      {showLimitationMessage && data.limitationMessage && (
                        <LimitationMessages
                          messages={data.limitationMessage}
                          setModalOpen={setModalOpen}
                        />
                      )}
                    </div>
                  </Grid>
                  {params.schemeType === "cycle_to_work" && (
                    <Disclaimer>
                      <StyledParagraph>
                        {t("basket:basket.footer")}
                      </StyledParagraph>
                    </Disclaimer>
                  )}
                </Grid>
              </Container>
              {!isDesktop && (
                <ButtonContainer>
                  <Button
                    variant="gradient"
                    disabled={limitExceeded ?? false}
                    style={{
                      width: "96.5%",
                      margin: "0.5rem auto",
                    }}
                    onClick={() => {
                      navigate("../checkout")
                    }}
                  >
                    {t("basket:basket.continueOrder")}
                  </Button>
                  <ContinueShoppingButton
                    sx={{ margin: "1rem", textDecoration: "underline" }}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ")
                        navigateToStoreHome()
                    }}
                    onClick={navigateToStoreHome}
                  >
                    &lt; {t("basket:basket.continueShopping")}
                  </ContinueShoppingButton>
                </ButtonContainer>
              )}
              {isDesktop && data.totalSavings && (
                <SavingsBanner
                  savings={data.totalSavings}
                  label={t("basket:basket.label")}
                  orderValue={data.totalRrp.toString()}
                  brochureGroup={brochureGroupId.toString()}
                />
              )}
            </>
          ) : (
            <EmptyBasket />
          )}
        </>
      )}
    </BasketContext.Provider>
  )
}

const Disclaimer = styled("div")`
  margin: 1rem auto 2.5rem auto;
  font-size: 12px;
  color: #35185f;
  line-height: 14px;
  text-align: center;
`
const StyledParagraph = styled("p")(
  ({ theme }) => css`
    max-width: 317px;
    margin: auto;
    ${theme.breakpoints.up("sm")} {
      max-width: 783px;
    }
  `
)

const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 0.5rem 0;
    z-index: 10;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      position: unset;
      bottom: unset;
      background-color: unset;
      padding: unset;
    }
  `}
`

const ContinueShoppingButton = styled("div")`
  ${({ theme }) => css`
    align-self: center;
    color: ${theme.palette.primary.main};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
    :focus {
      outline: ${theme.palette.secondary.main} solid 0.2rem;
    }
  `}
`
