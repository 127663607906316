import { Box, css, styled } from "@mui/material"
import { ImageContainer } from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"

export const SecondaryContainer = styled(Box)`
  ${({ theme }) => css`
    width: 96%;
    max-height: 59.375rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    text-align: left;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 47.75rem;
      height: 11.875rem;
      padding-right: unset;
      padding-left: 0.625rem;
      text-align: unset;
    }
  `}
`
export const ImageWrap = styled(Box)`
  ${({ theme }) => css`
    height: 17.8125rem;
    display: block;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      display: none;
    }
  `}
`
export const ImageWrapper = styled(ImageWrap)`
  && {
    height: 12.5rem;
  }
`

export const ImageContainerStyle = styled(ImageContainer)`
  max-width: 34.5625rem;
  height: 20.3125rem;
`
