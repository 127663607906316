import Footer from "./footer/Footer"
import Header from "./header/Header"
import { StaticLayoutContext, useStaticLayoutData } from "./StaticLayoutContext"
import { Outlet } from "react-router"
import { useEffect } from "react"
import extractSubdomain from "../../utils/extractSubdomain"
export const StaticLayout = function ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const contextValue = useStaticLayoutData()
  const PageName = () => {
    const url = location.href
    let sanitisedSchemeType
    if (url.includes("sign_up")) {
      sanitisedSchemeType = "Registration"
    } else if (url.includes("cookie_policy")) {
      sanitisedSchemeType = "Cookie policy"
    } else if (url.includes("sign_in")) {
      sanitisedSchemeType = "Sign In"
    } else if (url.includes("invitation/accept")) {
      sanitisedSchemeType = "Registration Confirmation"
    } else if (url.includes("cookie_list")) {
      sanitisedSchemeType = "Cookie list"
    } else if (
      url.includes("validation_numbers/new") ||
      url.includes("request_review") ||
      url.includes("confirmation_required")
    ) {
      sanitisedSchemeType = "Registration validation"
    } else if (url.includes("approval_required")) {
      sanitisedSchemeType = "Thank you"
    } else if (url.includes("password/edit")) {
      sanitisedSchemeType = "Change your password"
    } else if (url.includes("password_expired")) {
      sanitisedSchemeType = "Renew your password"
    } else if (url.includes("analytics")) {
      sanitisedSchemeType = "MI Reporting"
    } else {
      sanitisedSchemeType = "Not found"
    }
    return sanitisedSchemeType
  }

  useEffect(() => {
    const currentUrl = location.href
    const subdomain = extractSubdomain(currentUrl)

    document.title = `${subdomain}: ${PageName()}`
  }, [])

  return (
    <StaticLayoutContext.Provider value={contextValue}>
      <>
        <Header
          currentUserId={contextValue.currentUserId}
          showBasket={false}
          showNoticeboard={[]}
          backendUser={contextValue.backendUser}
          reporter={contextValue.reporter}
          isOrganisationSettingUser={contextValue.isOrganisationSettingUser}
          isPlatformUserManagementUser={
            contextValue.isPlatformUserManagementUser
          }
          employeeOrganisations={contextValue.employeeOrganisations}
        />
        <div className="static-main-container" id="main-content">
          <Outlet />
        </div>
        <Footer
          privacyNoticeUrl={contextValue.privacyNoticeUrl}
          organisationPrivacyNotice={contextValue.organisationPrivacyNotice}
          setShouldShowCookiePopup={setShouldShowCookiePopup}
          showAccessibility={contextValue.showAccessibility}
          showContactUs={contextValue.showContactUs}
          showCookiePolicy={contextValue.showCookiePolicy}
          showManageCookies={contextValue.showManageCookies}
          showOrganisationPrivacyNotice={
            contextValue.showOrganisationPrivacyNotice
          }
          showPrivacyPolicy={contextValue.showPrivacyPolicy}
          showPromotionalTermsAndConditions={
            contextValue.showPromotionalTermsAndConditions
          }
          showTermsAndConditions={contextValue.showTermsAndConditions}
          showVivupPrivacyNotice={contextValue.showVivupPrivacyNotice}
          customFooterLinks={contextValue.customFooterLinks}
        />
      </>
    </StaticLayoutContext.Provider>
  )
}
